import dayjs from "dayjs";
import Filters from "@/libs/filters.js";

export default {
  name: "OrderHistory",
  data: () => ({
    dayjs,
    is_show: false,
    settlement_confirmation_use: "",
    is_click: false,
  }),
  created: function () {
    //
  },
  methods: {
    show: async function () {
      this.is_show = true;
      this.settlement_confirmation_use = this.$parent.settlement_confirmation_use;
    },
    save: async function () {
      if (this.is_click == true) {
        return;
      }
      if (this.$parent.settlement_confirmation_use == "enabled" && this.settlement_confirmation_use == "disabled") {
        if (confirm("'사용안함' 선택 시, 정산완료일이 지난 정산 건은 자동으로 정산완료 상태로 변경됩니다.")) {
          await this.setSettlementConfirmation();
        } else {
          this.is_click = false;
          return;
        }
      }
      await this.setSettlementConfirmationUse();
      this.$parent.settlement_confirmation_use = this.settlement_confirmation_use;
      await this.$parent.searchSettlement();
      this.is_click = true;
      this.is_show = false;
      this.$parent.is_show = false;
    },
    // 정산확정 사용설정 조회
    setSettlementConfirmationUse: async function () {
      return await this.$store.dispatch(
        "supply/setSettlementConfirmationUse",
        this.settlement_confirmation_use
      );
    },
    closeModal: function () {
      this.is_show = false;
      this.$parent.is_show = false;
    },
    // 정산 확정 변경
    setSettlementConfirmation: async function () {
      let list = [];
      for (const settlement of this.$parent.settlement_info.list) {
        // 정산 보류가 있으면 건너뛴다
        if (Number(settlement.settlement_defer_amount) > 0) {
          continue;
        }
        const today = dayjs().format("YYYYMMDD");
        const settlement_schedule_at = dayjs(settlement.settlement_schedule_at).format("YYYYMMDD");
        if (Number(today) >= Number(settlement_schedule_at)) {
          list.push({
            settlement_schedule_at: settlement.settlement_schedule_at,
            supplier_code: settlement.supplier_code,
          });
        }
      }
      if (list.length > 0) {
        await this.$store.dispatch("supply/setSettlementConfirmation", list);
      }
    },
  },
  components: {},
  filters: Filters,
};
