<template>
  <div class="components overFlowAuto" style="min-width: 1160px;">
    <div class="headingArea">
      <div class="mTitle">
        <div class="ec-base-box typeThin supply_main board_background mt-2">
          <button type="button" class="icon eTip mr-2 cursor-auto">도움말</button>
          <p v-show="user_type == 'P'" class="board_background">
            공급사별 <strong>정산예정 또는 정산완료된 내역을 확인</strong>할 수 있으며, <strong>수기 및 정산 보류 처리가 가능</strong>합니다.<br>
            보류 처리를 하면 자동으로 정산 완료처리가 되지 않으며, 보류 해제시 다가오는 정산예정일에 내역이 포함됩니다.
          </p>
          <p v-show="user_type == 'S'" class="board_background">
            정산예정 또는 정산완료된 상세내역을 확인할 수 있습니다.<br>
            기간/검색어/정산항목/주문상태/정산완료여부에 따른 상세 검색이 가능합니다.
          </p>
        </div>
        <h1 class="page-title">정산상세 내역</h1>
      </div>
    </div>
    <Detail></Detail>
  </div>
</template>

<script>
  import Detail from "@/components/detail/Detail.vue";

  export default {
    name: "HomeView",
    components: {
      Detail,
    },
    data: () => ({
      user_type: sessionStorage.getItem("user_type"),
    }),
  };
</script>
