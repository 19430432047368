import dayjs from "dayjs";
import Filters from "@/libs/filters.js";

export default {
    name: "OrderHistory",
    data: () => ({
        dayjs,
        is_show: false,
        is_agree_check: false,
        textarea_content: "",
    }),
    created: function () {
        //
    },
    methods: {
        show: function () {
            this.is_show = true;
            this.$parent.is_show = true;
        },
        closeModal: function () {
            this.is_show = false;
            this.$parent.is_show = false;
        },
        setAgree: async function () {
            if (this.is_agree_check === false) {
                alert("이용약관 동의에 체크해주세요.");
                return;
            }
            const params = {
                agree_status: "agree"
            };
            await this.$store.dispatch("common/setAgree", params);
            sessionStorage.setItem("supply_agree_status", "agree");
            this.is_show = false;
            this.$parent.is_show = false;
        },
    },
    components: {
        //
    },
    filters: Filters,
};
