import dayjs from "dayjs";
import Filters from "@/libs/filters.js";

export default {
  name: "ClaimCode",
  data: () => ({
    dayjs,
    is_show: false,
  }),

  created: function () {
    //
  },


  methods: {
    show: function() {
      this.is_show = true;
    },
    closeModal: function() {
      this.is_show = false;
      this.$parent.is_sub_show = false;
    }
  },

  components: {
    
  },
  filters: Filters,
};
