const mutations = {
  setMallId(state, mall_id) {
    state.mall_id = mall_id;
  },
  setToken(state, payload) {
    sessionStorage.setItem("supply_access_token", payload.access_token);
    state.access_token = payload.access_token;
    state.refresh_token = payload.refresh_token;
  },
  setShops(state, payload) {
    state.shop_list = [];
    for (const shop of payload) {
      let set_param = {
        shop_no: shop.shop_no,
        shop_name: shop.shop_name,
      };
      state.shop_list.push(set_param);
    }
  },
};

export default mutations;
