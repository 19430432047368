export default Object.freeze({
  /**
   * 토큰 갱신
   */
  "/get/auth/refresh": {
    path: "/app/vendor-settlement/cafe24/{1}/auth/refresh",
    method: "GET",
  },
  /**
   * 샵 리스트 조회
   */
  "/get/shops": {
    path: "/app/vendor-settlement/mall/{1}/api/shops",
    method: "GET",
  },
  /**
   * 이미지 업로드
   */
  "/upload/image": {
    path: "/app/vendor-settlement/mall/{1}/api/suppliers/shops/{2}/upload/image",
    method: "POST",
  },
  /**
   * 공급사 리스트 조회
   */
  "/get/suppliers": {
    path: "/app/vendor-settlement/mall/{1}/api/suppliers/settlement/users",
    method: "GET",
  },
  /**
   * 이용약관 동의 조회
   */
  "/get/agree": {
    path: "/app/vendor-settlement/mall/{1}/api/suppliers/settlement/agree/{2}",
    method: "GET",
  },
  /**
   * 이용약관 동의 전송
   */
  "/post/agree": {
    path: "/app/vendor-settlement/mall/{1}/api/suppliers/settlement/agree/{2}?shop_no={3}",
    method: "POST",
  },
  /**
   * 정산예정/완료 내역 조회
   */
  "/get/settlement": {
    path: "/app/vendor-settlement/mall/{1}/api/suppliers/settlement/list",
    method: "GET",
  },
  /**
   * 정산확정 상태 조회
   */
  "/get/settlement/completion/use": {
    path: "/app/vendor-settlement/mall/{1}/api/suppliers/settlement/manual/use",
    method: "GET",
  },
  /**
   * 정산확정 상태 저장
   */
  "/post/settlement/completion/use": {
    path: "/app/vendor-settlement/mall/{1}/api/suppliers/settlement/manual/use?shop_no={2}",
    method: "POST",
  },
  /**
   * 쇼핑몰 이름 조회
   */
  "/get/store": {
    path: "/app/vendor-settlement/mall/{1}/api/suppliers/settlement/store",
    method: "GET",
  },
  /**
   * 정산상세 내역 조회
   * /api/suppliers/settlement/detail/list
   */
  "/get/settlement/detail": {
    path: "/app/vendor-settlement/mall/{1}/api/suppliers/settlement/detail/list",
    method: "GET",
  },
  /**
   * 공급사 설정 조회
   */
  "/get/suppliers/setting": {
    path: "/app/vendor-settlement/mall/{1}/api/suppliers/settlement/suppliers",
    method: "GET",
  },
  /**
   * 수기정산 요청
   */
  "/post/settlement/manual": {
    path: "/app/vendor-settlement/mall/{1}/api/suppliers/settlement/manual?shop_no={2}",
    method: "POST",
  },
  /**
   * 정산 상세 보기
   */
  "/get/settlement/detail/view": {
    path: "/app/vendor-settlement/mall/{1}/api/suppliers/settlement/detail/{2}?shop_no={3}",
    method: "GET",
  },
  /**
   * 정산 상세 수정
   */
  "/put/settlement/detail/view": {
    path: "/app/vendor-settlement/mall/{1}/api/suppliers/settlement/detail/{2}?shop_no={3}",
    method: "PUT",
  },
  /**
   * 정산 상세 삭제
   */
  "/delete/settlement/detail/view": {
    path: "/app/vendor-settlement/mall/{1}/api/suppliers/settlement/detail/{2}?shop_no={3}",
    method: "DELETE",
  },
  /**
   * 정산 보류
   */
  "/post/settlement/pending": {
    path: "/app/vendor-settlement/mall/{1}/api/suppliers/settlement/pending?shop_no={2}",
    method: "POST",
  },
  /**
   * 정산 보류 해제
   */
  "/post/settlement/prepare": {
    path: "/app/vendor-settlement/mall/{1}/api/suppliers/settlement/prepare?shop_no={2}",
    method: "POST",
  },
  /**
   * 정산 예정/완료 요약 조회
   */
  "/get/settlement/summary": {
    path: "/app/vendor-settlement/mall/{1}/api/suppliers/settlement/summary",
    method: "GET",
  },
  /**
   * 정산확정 변경
   */
  "/post/settlement/manual/confirm": {
    path: "/app/vendor-settlement/mall/{1}/api/suppliers/settlement/manual/confirm?shop_no={2}",
    method: "POST",
  },
});
