const XLSX = require("xlsx");

export default class Excel {
  /**
   *
   * @param {*} file
   * @param {*} sheetKeyList
   */
  constructor(file, sheetKeyList) {
    this._file = file;
    this._headerKey = sheetKeyList;
    this._sheet = null;
    this._wb = null;
  }

  /**
   * 엑셀 다운로드 시작
   */
  initSheet() {
    this._wb = XLSX.utils.book_new();
  }

  /**
   * 시트 생성 및 추가
   * @param {*} data
   */
  addSheet(data) {
    let headerValue = Object.values(this._headerKey);
    let sheetData = this.changeSheetHeader(data);
    if (this._sheet === null) {
      this._sheet = XLSX.utils.json_to_sheet(sheetData, {
        header: headerValue,
      });
    } else {
      XLSX.utils.sheet_add_json(this._sheet, sheetData, {
        origin: -1,
        skipHeader: true,
        header: headerValue,
      });
    }
  }

  /**
   * 종료
   */
  endSheet(fileName) {
    XLSX.utils.book_append_sheet(this._wb, this._sheet, fileName);
    XLSX.writeFile(this._wb, fileName + ".xlsx");
    this._sheet = null;
    this._wb = null;
  }

  /**
   * 우선 첫번째 시트만 가져옴 json으로 나중에 확장필요하면 하기
   */
  async getJson() {
    let reader = new FileReader();
    reader.readAsBinaryString(this._file);
    let result = await new Promise((resolve) => {
      reader.onload = () => {
        let fileData = reader.result;
        let wb = XLSX.read(fileData, { type: "binary" });
        this._sheetNameList = wb.SheetNames; // 시트 이름 목록 가져오기
        this._firstSheetName = this._sheetNameList[0]; // 첫번째 시트명
        this._firstSheet = wb.Sheets[this._firstSheetName];
        let resultSheet = XLSX.utils.sheet_to_json(this._firstSheet);
        if (this._headerKey) {
          resultSheet = this.changeSheetHeader(resultSheet);
        }

        resolve(resultSheet);
      };
    });
    return result;
  }

  changeSheetHeader(sheet) {
    let result = [];
    sheet.forEach((list) => {
      let data = {};
      for (let k in list) {
        for (let h in this._headerKey) {
          if (h === k) {
            data[this._headerKey[h]] = list[k];
          }
        }
      }
      if (data !== null) {
        result.push(data);
      }
    });
    return result;
  }

  setHeaderKey(sheetKeyList) {
    this._headerKey = sheetKeyList;
  }
  appendSheet(fileName, data) {
    let top_param = [""];

    if (data.length > 0) {
      for (const row in data[0]) {
        if (row.length == 12) {
          top_param.push(row);
        }
      }
    }

    let headerValue = Object.values(this._headerKey);
    let sheetData = this.changePrescribeExcelSheetHeader(data);

    let params = [
      top_param,
      headerValue,
    ];
    for (const sheet_data of sheetData) {
      let sheet_data_list = [];
      for (const value of headerValue) {
        if (sheet_data[value]) {
          sheet_data_list.push(sheet_data[value]);
        } else {
          sheet_data_list.push("");
        }
      }
      params.push(sheet_data_list);
    }

    // 새 시트 생성
    const worksheet = XLSX.utils.aoa_to_sheet(params);

    // 헤더를 2번째 줄로 이동
    // worksheet['!range'].s.r = 1;

    // 시트를 워크북에 추가
    XLSX.utils.book_append_sheet(this._wb, worksheet, fileName);

  }
  finishSheet(fileName) {
    XLSX.writeFile(this._wb, fileName + ".xlsx");
    this._sheet = null;
    this._wb = null;
  }
  changePrescribeExcelSheetHeader(sheet) {
    let result = [];
    sheet.forEach((list) => {
      let data = {};
      for (let k in list) {
        for (let h in this._headerKey) {
          if (h === k) {
            if (k.indexOf("group_concat") > -1 || k == "ingredient" || k == "bom_order_number") {
              data[this._headerKey[h]] = list[k];
            } else {
              if (list[k]) {
                data[this._headerKey[h]] = Number(list[k]);
              } else {
                data[this._headerKey[h]] = list[k];
              }
            }
          }
        }
      }
      if (data !== null) {
        result.push(data);
      }
    });
    return result;
  }
}
