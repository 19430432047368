import { mapState } from "vuex";
import MallName from "@/assets/images/mall_name.png";

export default {
  name: "left_menu",
  data: () => ({
    MallName,
    shop_no: 1,
    sub_menu_on: {
      schedule: "sub_menu_on",
      completion: "",
      detail: "",
      write: "",
    },
    user_type: sessionStorage.getItem("user_type"),
    mall_name: "",
  }),
  computed: {
    ...mapState("common", [""]),
  },
  methods: {
    reset: function () {
      Object.assign(this.$data, this.$options.data());
    },
    movePage: function (page_name) {
      this.$router.push({
        name: `${page_name}`,
      });
      this.changeSelectMenu(page_name);
    },
    changeSelectMenu: function (page_name) {
      this.sub_menu_on = {
        schedule: "",
        completion: "",
        detail: "",
        write: "",
      };
      this.sub_menu_on[page_name] = "sub_menu_on";
    }
  },

  created: async function () {
    this.movePage(this.$route.name);
  },
  mounted: async function () {
    if (sessionStorage.getItem("supply_shop_name")) {
      this.mall_name = sessionStorage.getItem("supply_shop_name");
    }
    if (sessionStorage.getItem("supply_supplier_name")) {
      this.mall_name = sessionStorage.getItem("supply_supplier_name");
    }
  },
};
