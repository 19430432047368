import dayjs from "dayjs";
import Filters from "@/libs/filters.js";

export default {
  name: "CollectionDetail",
  data: () => ({
    dayjs,
    is_show: false,
    user_type: sessionStorage.getItem("user_type"),
    detail: {
      supplier_code: "",
      supplier_name: "",
      settlement_no: "",
      order_id: "",
      order_item_code: "",
      exchange_no: "",
      return_no: "",
      order_status: "",
      settlement_category: "",
      product_name: "",
      option_name: "",
      quantity: "",
      sale_price: "",
      settlement_amount: "",
      shipping_fee: "",
      regional_surcharge_amount: "",
      individual_shipping_fee: "",
      additional_shipping_fee: "",
      commission_amount: "",
      commission_percent: "",
      penalty_amount: "",
      deduction_amount: "",
      refund_amount: "",
      settlement_defer_amount: "",
      settlement_schedule_at: "",
      settlement_completion_at: "",
      relation_order_item_code: [],
      settlement_status: "",
      is_write: "",
      reason: "",
    },
    is_update: false,
  }),

  created: function () {
    // getSettlementDetailView
  },

  methods: {
    reset: function () {
      Object.assign(this.$data, this.$options.data());
    },
    show: async function (settlement_no) {
      this.reset();
      const result = await this.getSettlementDetailView(settlement_no);
      await this.setSettlementDetailView(result);
      this.is_show = true;
    },
    closeModal: function () {
      this.is_show = false;
      this.$parent.is_sub_show = false;
    },
    getSettlementDetailView: async function (settlement_no) {
      return await this.$store.dispatch("supply/getSettlementDetailView", settlement_no);
    },
    setSettlementDetailView: function (params) {
      if (params.is_write == "enabled") {
        params.product_name = params.reason;
      }
      this.detail = params;
    },
    comma: function (number) {
      if (!number || number == 0) {
        return 0;
      }
      return number.toLocaleString();
    },
    getOrderStatus: function (value) {
      if (value == "normal") {
        return "정상";
      }
      if (value == "cancel") {
        return "취소";
      }
      if (value == "return") {
        return "반품";
      }
      if (value == "exchange") {
        return "교환";
      }
    },
    getSettlementCategory: function (value) {
      if (value == "product") {
        return "주문상품";
      }
      if (value == "penalty") {
        return "패널티";
      }
      if (value == "deduction") {
        return "공제";
      }
      if (value == "refund") {
        return "환급";
      }
    },
    update: function () {
      this.is_update = true;
    },
    save: async function () {
      //
      await this.putSettlementDetailView();
    },
    updateCancel: async function () {
      const result = await this.getSettlementDetailView(this.detail.settlement_no);
      await this.setSettlementDetailView(result);
      this.is_update = false;
    },
    putSettlementDetailView: async function () {
      // const settlement_amount = this.detail.settlement_amount.replace(/[^0-9]/g, "");
      let params = {
        settlement_no: this.detail.settlement_no,
        settlement_amount: Number(this.detail.settlement_amount),
        relation_order_item_code: this.detail.relation_order_item_code,
      };
      params.reason = this.detail.product_name;
      await this.$store.dispatch("supply/putSettlementDetailView", params);
      alert("수정되었습니다");
      await this.$parent.searchSettlement();
      this.closeModal();
    },
    deleted: async function () {
      await this.$store.dispatch("supply/deleteSettlementDetailView", this.detail.settlement_no);
      alert("삭제되었습니다");
      await this.$parent.searchSettlement();
      this.closeModal();
    },
    onlyNumber: function (e) {
      let val = e.target.value;
      let replaceVal = val.replace(/[^0-9|-]/g, "");
      replaceVal = this.chgMinusFormat(replaceVal);
      /*
      let parts = replaceVal.toString().split(".");
      if (replaceVal.substring(replaceVal.length - 1, replaceVal.length) == ".") {
        replaceVal = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".";
      } else {
        replaceVal =
          parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
      }
      */

      e.target.value = replaceVal;

      let event = new Event("input", {
        bubbles: true,
        cancelable: true,
      });
      e.target.dispatchEvent(event);
    },
    chgMinusFormat: function (str) {
      let idx = str.indexOf("-");
      if (idx == 0) {
        let tmpStr = str.substr(idx + 1);
        //뒤에 마이너스가 또 있는지 확인
        if (tmpStr.indexOf("-") >= 0) {
          tmpStr = tmpStr.replace("-", "");
          str = str.substr(0, idx + 1) + tmpStr;
        }
      } else if (idx > 0) {
        str = str.replace("-", "");
      } else if (idx < 0) {
        return str;
      }
      return str;
    },
    dateFormat: function (date) {
      if (!date) {
        return date;
      }
      return dayjs(date).format("YYYY-MM-DD");
    },
    setOrderItemCode: function (order_item_code) {
      if (order_item_code == "manual") {
        return "";
      } else {
        return order_item_code;
      }
    }
  },

  components: {},
  filters: Filters,
};
