export default {
  name: "supply_header",
  data: () => ({
    header_style: {
      background: "",
    },
  }),
  methods: {
    mainMovePage: async function () {
      //
    },
    // 공급사 리스트 조회
    getSuppliers: async function () {
      return await this.$store.dispatch("common/getSuppliers");
    },
    // 공급사 리스트 입력
    setSuppliers: function (list) {
      let supplier_list = [];
      for (const supplier of list) {
        if (supplier.supplier_code != "S0000000") {
          supplier_list.push(supplier);
        }
        if (supplier.user_id == sessionStorage.getItem("supply_user_id")) {
          sessionStorage.setItem("supply_supplier_code", supplier.supplier_code);
          this.$parent.$refs.LeftMenu.mall_name = supplier.supplier_name;
          sessionStorage.removeItem("supply_supplier_name");
          sessionStorage.setItem("supply_supplier_name", supplier.supplier_name);
        }
      }
      localStorage.setItem("supply_supplier_list", JSON.stringify(supplier_list));
    },
    getStore: async function (user_type) {
      sessionStorage.removeItem("supply_shop_name");
      if (user_type == "P") {
        const result = await this.$store.dispatch("common/getStore");
        sessionStorage.setItem("supply_shop_name", result.shop_name);
        this.$parent.$refs.LeftMenu.mall_name = result.shop_name;
      }
    },
    getAgree: async function (user_type) {
      sessionStorage.removeItem("supply_agree_status");
      if (user_type == "P") {
        const result = await this.$store.dispatch("common/getAgree");
        sessionStorage.setItem("supply_agree_status", result.agree_status);
      }
    },

    getSupplierSetting: async function () {
      return await this.$store.dispatch("supply/getSupplierSetting");
    },

    setSupplierSetting: async function (params) {
      localStorage.setItem("supply_supplier_setting", JSON.stringify(params));
    },
    sleep: async function (sec) {
      return new Promise(function(resolve, reject) {
        setTimeout(async function() {
          resolve(null);
        }, sec);
      });
    }
  },
  created: async function () {
    const header_query = Object.assign({}, this.$route.query);
    if (header_query?.user_type) {
      if (header_query.user_type == "A") {
        header_query.user_type = "P";
      }
      sessionStorage.setItem("user_type", header_query.user_type);
      if (header_query?.user_type == "S") {
        this.header_style.background = "#3c69dd";
      }
    }
    if (header_query?.mall_id) {
      sessionStorage.setItem("supply_mall_id", header_query.mall_id);
    }
    if (header_query?.shop_no) {
      sessionStorage.setItem("supply_shop_no", header_query.shop_no);
    }
    if (header_query?.user_id) {
      sessionStorage.setItem("supply_user_id", header_query.user_id);
    }
    if (header_query?.access_token) {
      sessionStorage.setItem("supply_access_token", header_query.access_token);

      // 공급사 리스트 조회
      let suppliers = await this.getSuppliers();
      this.setSuppliers(suppliers.list);

      // 쇼핑몰명 조회
      await this.getStore(header_query.user_type);
      // 동의 조회
      await this.getAgree(header_query.user_type);
    }
    if (sessionStorage.getItem("user_type") == "S") {
      this.header_style.background = "#3c69dd";
    }
    await this.sleep(1000);
  },
  mounted: async function () {
    if (sessionStorage.getItem("supply_shop_name")) {
      this.$parent.$refs.LeftMenu.mall_name = sessionStorage.getItem("supply_shop_name");
    }

    const setting = await this.getSupplierSetting();
    this.setSupplierSetting(setting);
  },
};
