export default Object.freeze({
  singularStatus: (value) => {
    return value === "enabled" ? "단수" : "복수";
  },
  comma: (value) => {
    return value.toLocaleString("ko-KR");
  },
  flexibleMin: (param) => {
    if (param.re_prescription == "flexible") {
      return "Flexible";
    } else {
      return param.re_contain_percent.min ? param.re_contain_percent.min : 0;
    }
  },
  flexibleMax: (param) => {
    if (param.re_prescription == "flexible") {
      return "Flexible";
    } else {
      return param.re_contain_percent.max ? param.re_contain_percent.max : 0;
    }
  },
  rePrescription: (value) => {
    if (!value.re_prescription) {
      return "불가능";
    }
    if (value.re_prescription == "disabled") {
      return "불가능";
    }
    if (value.re_prescription == "enabled") {
      return "가능 / " + value.score + "%씩 조정";
    }
    if (value.re_prescription == "formula") {
      return "계산식 반영";
    }
    if (value.re_prescription == "flexible") {
      return "Flexible";
    }
    return value;
  },
  useStatus: (value) => {
    if (value == "enabled") {
      return "사용함";
    } else {
      return "사용안함";
    }
  },
  displayTypeStaus: (value) => {
    if (value == "image") {
      return "이미지";
    } else if (value == "html") {
      return "html";
    } else {
      return "설정안함";
    }
  },
  anserTypeStaus: (value) => {
    if (value == "text") {
      return "텍스트";
    }
    if (value == "image") {
      return "이미지";
    }
    if (value == "width_slide_image") {
      return "가로슬라이드";
    }
    if (value == "height_slide_image") {
      return "세로슬라이드";
    }
    if (value == "scent") {
      return "향";
    }
    if (value == "gender_age") {
      return "성별/나이";
    }
    if (value == "nick_name") {
      return "닉네임";
    }
    if (value == "text_subject_content") {
      return "텍스트 (제목+내용)";
    }
    if (value == "text_content") {
      return "텍스트 (내용)";
    }
    if (value == "image_right_thumb") {
      return "이미지 (우측 썸네일형)";
    }
    if (value == "image_answer_select") {
      return "이미지 (답변 선택 노출형)";
    }
    if (value == "width_slide_image") {
      return "가로슬라이드 (이미지형)";
    }
    if (value == "width_slide_text") {
      return "가로슬라이드 (텍스트형)";
    }
    if (value == "width_slide_select") {
      return "가로슬라이드 (선택형)";
    }
    if (value == "width_slide_both_side_image") {
      return "가로슬라이드 (좌/우 이미지 노출형)";
    }
    if (value == "width_slide_radio") {
      return "가로슬라이드 (라디오 버튼형)";
    }
    if (value == "width_slide_icon_select") {
      return "가로슬라이드 (아이콘 선택형)";
    }
    if (value == "height_slide_button") {
      return "세로슬라이드 (버튼형)";
    }

    return "O/X";
  },
  salesStatus: (value) => {
    if (value == "enabled") {
      return "판매중";
    } else {
      return "판매안함";
    }
  },
  formulaSpecType: (value) => {
    if (value == "sum") {
      return "합산점수사양";
    } else if (value == "bulk") {
      return "벌크사양";
    } else if (value == "basic") {
      return "기본사양";
    } else if (value == "choice") {
      return "선택사양";
    }
  },
  containSpecType: (value) => {
    if (value == "designate") {
      return "지정";
    } else if (value == "answer_value") {
      return "문진 답변 값";
    } else if (value == "flexible") {
      return "Flexible";
    }
  },
  formulaCondition: (value) => {
    if (value == "gte") {
      return "이상";
    } else if (value == "gt") {
      return "초과";
    } else if (value == "eq") {
      return "같음";
    } else if (value == "lte") {
      return "이하";
    } else if (value == "lt") {
      return "미만";
    }
  },
  scalpTypeFormat: (value) => {
    if (value == "O") {
      return "O (지성)";
    } else if (value == "N") {
      return "N (중성)";
    } else {
      return "D (건성)";
    }
  },
  sensitiveTypeFormat: (value) => {
    if (value == "S") {
      return "S (민감성)";
    } else {
      return "R (비민감성)";
    }
  },
  hairTypeFormat: (value) => {
    if (value == "H") {
      return "H (건강모)";
    } else if (value == "D") {
      return "D (손상모)";
    } else {
      return "E (극손상모)";
    }
  },
  hairLossTypeFormat: (value) => {
    if (value == "L") {
      return "L (고민 있음)";
    } else {
      return "W (고민 없음)";
    }
  },
  subJectLineBreak: (value) => {
    return value.replace(/(\n|\r\n)/g, "<br>");
  },
  removeTag: (value) => {
    if (!value) {
      return value;
    }
    const str_value = String(value);
    return str_value.replace(/<[^>]*>?/g, "");
  },
  reQnaQuestionTypyFormat: (value) => {
    if (value == "base") {
      return "베이스 조정";
    } else if (value == "main") {
      return "기능 선택 (성분 함량 조정)";
    } else if (value == "scent") {
      return "향 선택";
    } else if (value == "nick_name") {
      return "닉네임 입력";
    }

    if (value == "text_subject_content") {
      return "텍스트 (제목+내용)";
    }
    if (value == "text_content") {
      return "텍스트 (내용)";
    }
    if (value == "image_right_thumb") {
      return "이미지 (우측 썸네일형)";
    }
    if (value == "image_answer_select") {
      return "이미지 (답변 선택 노출형)";
    }
    if (value == "width_slide_image") {
      return "가로슬라이드 (이미지형)";
    }
    if (value == "width_slide_text") {
      return "가로슬라이드 (텍스트형)";
    }
    if (value == "width_slide_select") {
      return "가로슬라이드 (선택형)";
    }
    if (value == "width_slide_both_side_image") {
      return "가로슬라이드 (좌/우 이미지 노출형)";
    }
    if (value == "width_slide_radio") {
      return "가로슬라이드 (라디오 버튼형)";
    }
    if (value == "width_slide_icon_select") {
      return "세로슬라이드 (버튼형)";
    }
    if (value == "height_slide_button") {
      return "세로슬라이드 (버튼형)";
    }

    if (value == "worry") {
      return "고민해결";
    }
    if (value == "feedback") {
      return "피드백";
    }
    if (value == "worry_select") {
      return "고민 선택";
    }
    if (value == "add_worry_select") {
      return "추가 고민 선택";
    }
    return "일반 질문";
  },
  qnaQuestionTypeFormat: (value) => {
    if (value == "main") {
      return "메인 질문";
    }
    if (value == "normal") {
      return "일반 질문";
    }
    if (value == "scent") {
      return "향 선택";
    }
    if (value == "gender_age") {
      return "성별/나이 선택";
    }
    if (value == "nick_name") {
      return "닉네임 입력";
    }
  },

  skinCareWorryType: (value) => {
    if (!value) {
      return "없음";
    }
    if (value == "dry") {
      return "건조";
    }
    if (value == "color") {
      return "색소";
    }
    if (value == "wrinkle") {
      return "주름";
    }
    if (value == "sensitive") {
      return "민감";
    }
    if (value == "pore") {
      return "모공";
    }
  },
  skinCareContainSpecType: (value) => {
    if (value == "designate") {
      return "지정";
    } else if (value == "ampoule") {
      return "앰플선택 : ";
    } else if (value == "flexible") {
      return "Flexible";
    } else if (value == "concept_ampoule") {
      return "";
    }
  },
  getProductType: (value) => {
    if (value == "hair_care") {
      return "헤어케어";
    } else if (value == "skin_care") {
      return "스킨케어";
    } else {
      return "헤어케어";
    }
  },
  feedbackFormulaStatus: (value) => {
    if (value && value == "enabled") {
      return " (피드백 계산식)";
    }
    return "";
  },
});
