<template>
  <div class="components overFlowAuto" style="min-width: 1160px;">
    <div class="headingArea">
      <div class="mTitle">

        <div class="ec-base-box typeThin supply_main board_background mt-2">
          <button type="button" class="icon eTip mr-2 cursor-auto">도움말</button>
          <p v-show="user_type == 'P'" class="board_background">
            공급사별 <strong>정산주기 및 기간(년/월)을 선택하여 정산완료 내역을 확인</strong>할 수 있습니다.<br>
            정산완료일을 클릭하면 해당 공급사의 상세 정산 내역 확인이 가능합니다.
          </p>
          <p v-show="user_type == 'S'" class="board_background">
            공급사 정산주기 및 기간(년/월)을 선택하여 정산완료 내역을 확인할 수 있습니다.<br>
            정산완료일을 클릭하면 상세내역 확인이 가능합니다.
          </p>
        </div>

        <h1 class="page-title">정산완료 내역</h1>
      </div>
    </div>
    <Completion></Completion>
  </div>
</template>

<script>
import Completion from "@/components/completion/Completion.vue";

export default {
  name: "HomeView",
  components: {
    Completion,
  },
  data: () => ({
    user_type: sessionStorage.getItem("user_type"),
  }),
};
</script>
