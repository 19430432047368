import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import mutations from "./mutations";
import modules from "./modules";
import actions from "./actions";
import entries from "@/datas/entries.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    api_url: "https://vendor-settlement-api.shoplus.store", // live
    app_code: "vendor-settlement",
    // app_code: "supply",
    entries,
  },
  getters: {},
  mutations,
  actions,
  modules: {
    common: modules.common,
    supply: modules.supply,
  },
  plugins: [
    createPersistedState({
      paths: ["common", "supply"],
    }),
  ],
});
