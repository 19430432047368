import M from "@/libs/mapping.js";

const actions = {
  // 토큰재발급
  async getRefreshToken({ commit }) {
    try {
      const result = await M.api(M.entry("/get/auth/refresh"));
      commit("setToken", result);
    } catch (e) {
      const mall_id = sessionStorage.getItem("supply_mall_id");
      alert("카페24 인증키 값이 만료되었습니다.\n앱스토어 목록에서 다시 접속해주세요.");
      location.href = `https://${mall_id}.cafe24.com/disp/admin/shop1/myapps/list`;
      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });
    }
  },
  // 샵 리스트 조회
  async getShops({ commit }) {
    try {
      const result = await M.api(M.entry("/get/shops"));
      commit("setShops", result);
    } catch (e) {
      console.log(e);
    }
  },
  // 이미지 등록
  async postImage(commit, params) {
    if (!params.image) {
      return;
    }

    if (params.image.indexOf("http://") !== -1 || params.image.indexOf("https://") !== -1) {
      return params.image;
    }

    try {
      const result = await M.api(
        M.entry("/upload/image", sessionStorage.getItem("supply_shop_no")),
        params
      );
      return result.image;
    } catch (e) {
      // return "http://naver.com";
    }
  },
  // 공급사 리스트 조회
  async getSuppliers({ commit }) {
    const shop_no = sessionStorage.getItem("supply_shop_no");
    try {
      return await M.api(M.entry("/get/suppliers"), { shop_no });
    } catch (e) {
      console.log(e);
    }
  },
  // 이용약관 동의
  async getAgree({ commit }) {
    const shop_no = sessionStorage.getItem("supply_shop_no");
    try {
      let result = await M.api(
        M.entry(
          "/get/agree",
          sessionStorage.getItem("supply_user_id")
        ),
        { shop_no }
      );
      if (!result) {
        result = {
          agree_status: "disabled",
        };
      }
      return result;
    } catch (e) {
      console.log(e);
    }
  },
  async setAgree({ commit }, params) {
    try {
      return await M.api(
        M.entry(
          "/post/agree",
          sessionStorage.getItem("supply_user_id"),
          sessionStorage.getItem("supply_shop_no")
        ),
        params
      );
    } catch (e) {
      console.log(e);
    }
  },
  async getStore({ commit }) {
    const shop_no = sessionStorage.getItem("supply_shop_no")
    try {
      let result = await M.api(M.entry("/get/store"), { shop_no });
      if (!result) {
        result = {
          shop_name: "zzzz",
        };
      }
      return result;
    } catch (e) {
      console.log(e);
    }
  },
};

export default actions;
