import M from "@/libs/mapping.js";

const actions = {
  // 정산 예정/완료 내역 조회
  async getSettlementSummary({ commit }, params) {
    params.shop_no = sessionStorage.getItem("supply_shop_no");
    try {
      return await M.api(
        M.entry("/get/settlement/summary"),
        params
      );
    } catch (e) {
      console.log(e);
    }
  },
  // 정산 예정/완료 내역 조회
  async getSettlement({ commit }, params) {
    params.shop_no = sessionStorage.getItem("supply_shop_no");
    try {
      return await M.api(
        M.entry("/get/settlement"),
        params
      );
    } catch (e) {
      console.log(e);
    }
  },
  async getSettlementConfirmationUse({ commit }, params) {
    const shop_no = sessionStorage.getItem("supply_shop_no");
    try {
      let result = await M.api(
        M.entry("/get/settlement/completion/use"),
        { shop_no }
      );
      if (!result) {
        result = {
          status: "enabled",
        };
      }
      return result;
    } catch (e) {
      console.log(e);
    }
  },
  async setSettlementConfirmationUse({ commit }, param) {
    const shop_no = sessionStorage.getItem("supply_shop_no");
    try {
      let result = await M.api(
        M.entry("/post/settlement/completion/use", sessionStorage.getItem("supply_shop_no")),
        { status: param }
      );
      if (!result) {
        result = {
          status: "enabled",
        };
      }
      return result;
    } catch (e) {
      console.log(e);
    }
  },
  async setSettlementConfirmation({ commit }, param) {
    try {
      let result = await M.api(
        M.entry("/post/settlement/manual/confirm", sessionStorage.getItem("supply_shop_no")),
        param
      );
      if (!result) {
        result = {
          status: "enabled",
        };
      }
      return result;
    } catch (e) {
      console.log(e);
    }
  },
  // 정산 예정/완료 내역 조회
  async getSettlementDetailList({ commit }, params) {
    params.shop_no = sessionStorage.getItem("supply_shop_no");
    try {
      return await M.api(
        M.entry("/get/settlement/detail"),
        params
      );
    } catch (e) {
      console.log(e);
    }
  },
  // 공급사-설정-조회
  async getSupplierSetting({ commit }, shop_no) {
    if (!shop_no) {
      shop_no = sessionStorage.getItem("supply_shop_no");
    }
    try {
      return await M.api(
        M.entry("/get/suppliers/setting"),
        { shop_no }
      );
    } catch (e) {
      console.log(e);
    }
  },
  async setSettlementManual({ commit }, params) {
    await M.api(M.entry("/post/settlement/manual", sessionStorage.getItem("supply_shop_no")), params);
  },
  async getSettlementDetailView({ commit }, settlement_no) {
    try {
      return await M.api(
        M.entry(
          "/get/settlement/detail/view",
          settlement_no,
          sessionStorage.getItem("supply_shop_no"),
        )
      );
    } catch (e) {
      console.log(e);
    }
  },
  async putSettlementDetailView({ commit }, params) {
    let result = await M.api(
      M.entry(
        "/put/settlement/detail/view",
        params.settlement_no,
        sessionStorage.getItem("supply_shop_no"),
      ),
      params
    );
  },
  async deleteSettlementDetailView({ commit }, settlement_no) {
    let result = await M.api(
      M.entry(
        "/delete/settlement/detail/view",
        settlement_no,
        sessionStorage.getItem("supply_shop_no"),
      )
    );
  },

  async setSettlementPending ({ commit }, settlement_no) {
    return await M.api(
      M.entry(
        "/post/settlement/pending",
        sessionStorage.getItem("supply_shop_no"),
      ),
      settlement_no
    );
  },
  async setSettlementPrepare ({ commit }, settlement_no) {
    return await M.api(
      M.entry(
        "/post/settlement/prepare",
        sessionStorage.getItem("supply_shop_no"),
      ),
      settlement_no
    );
  }
};

export default actions;
