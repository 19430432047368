import dayjs from "dayjs";
import Paginate from "@/components/common/Paginate.vue";
import Filters from "@/libs/filters.js";
import ConfirmationUseStatus from "@/components/modal/ConfirmationUseStatus.vue";
import Agree from "@/components/modal/Agree.vue";
import Excel from "@/libs/excel.js";

export default {
  name: "Schedule",
  data: () => ({
    settlement_cycle_class: [
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
    ],
    settlement_cycle_type: [
      {
        type: "all",
      },
      {
        type: "month",
      },
      {
        type: "week",
      },
      {
        type: "day",
      },
    ],
    search_param: {
      type: "schedule",
      settlement_cycle: "month",
      supplier_code: "",
      offset: 0,
      limit: 20,
    },
    textarea_user_id_list: [],
    textarea_user_text: "",
    select_supplier: "",
    is_show: false,
    user_type: sessionStorage.getItem("user_type"),
    supplier_list: [],
    total_settlement_info: {
      total_sale_price: 0,
      total_settlement_amount: 0,
      total_commission_amount: 0,
      total_shipping_fee: 0,
      total_regional_surcharge_amount: 0,
      total_individual_shipping_fee: 0,
      total_additional_shipping_fee: 0,
      total_penalty_amount: 0,
      total_deduction_amount: 0,
      total_refund_amount: 0,
      total_settlement_defer_amount: 0,
    },
    settlement_info: {
      total_count: 0,
      list: [],
    },
    page_no: 1,
    list_number: 0,
    all_check: false,
    settlement_confirmation_use: "",
    excel_data: {
      Excel: null,
      total_limit: 200000,
      limit: 1000,
      total_count: 0,
      offset: 0,
      sheetData: [],
      sheetHeader: {
        supplier_name: "공급사명",
        supplier_code: "공급사 코드",
        settlement_cycle: "정산주기",
        settlement_schedule_at: "정산예정일",
        sale_price: "판매가 합계",
        settlement_amount: "정산금액",
        commission_amount: "수수료 금액",
        shipping_fee: "기본배송비",
        regional_surcharge_amount: "지역별 배송비",
        individual_shipping_fee: "개별 배송비",
        penalty_amount: "패널티 금액",
        deduction_amount: "공제 금액",
        refund_amount: "환급 금액",
        settlement_defer_amount: "정산 보류 금액",
      },
    },
    excel_loading: false,
    today : 0,
    is_modal_show: false,
  }),

  created: async function () {
    // 월간 정산 선택
    this.settlementCycleChange(0);
    await this.setQuery();
    this.$parent.$parent.$refs.LeftMenu.changeSelectMenu("schedule");
    this.today = Number(dayjs().format("YYYYMMDD"));
  },

  mounted: async function () {

    let supply_user_id = sessionStorage.getItem("supply_user_id");
    let supply_supplier_code = sessionStorage.getItem("supply_supplier_code");
    if (supply_user_id && !supply_supplier_code) {
      const result = await this.getSuppliers();
      if (result && result.list) {
        await this.setSuppliers(result.list);
      }
    }

    if (this.user_type == "P") {
      if (!sessionStorage.getItem("supply_agree_status")) {
        // 이용약관 동의 조회
        let agree = await this.getAgree();
        sessionStorage.setItem("supply_agree_status", agree.agree_status);
        if (agree.agree_status == "disagree") {
          this.$refs.Agree.show();
        }
      } else {
        if (sessionStorage.getItem("supply_agree_status") == "disagree") {
          this.$refs.Agree.show();
        }
      }
    }
    // this.setSuppliers();

    // 정산내역 조회
    this.total_settlement_info = await this.getSettlementSummary();

    let settlement_result = await this.getSettlement();
    await this.setSettlement(settlement_result);
    let result = await this.getSettlementConfirmationUse();
    this.settlement_confirmation_use = result.status;
    this.setListNumber();
    this.setPaging();
  },

  methods: {
    reset: function () {
      Object.assign(this.$data, this.$options.data());
    },
    setQuery: async function () {
      if (this.$route.query) {
        if (this.$route.query.settlement_cycle) {
          this.search_param.settlement_cycle = this.$route.query.settlement_cycle;
          if (this.search_param.settlement_cycle == "all") {
            this.settlementCycleChange(0);
          } 
          if (this.search_param.settlement_cycle == "month") {
            this.settlementCycleChange(1);
          }
          if (this.search_param.settlement_cycle == "week") {
            this.settlementCycleChange(2);
          }
          if (this.search_param.settlement_cycle == "day") {
            this.settlementCycleChange(3);
          }
        }
        if (this.$route.query.supplier_code) {
          this.search_param.supplier_code = this.$route.query.supplier_code;
        }
        if (this.$route.query.limit) {
          this.search_param.limit = this.$route.query.limit;
        }
        if (this.$route.query.textarea_user_text) {
          this.textarea_user_text = this.$route.query.textarea_user_text;
        }

        if (this.$route.query.page_no) {
          this.page_no = this.$route.query.page_no;
          this.search_param.offset = (this.page_no - 1) * this.search_param.limit
        }
      }
    },
    // 공급사 추가 textarea 입력 체크
    texareaInput: function (e) {
      this.$refs.textarea.style.height = "auto";
      if (this.$refs.textarea.scrollHeight < 30) {
        this.$refs.textarea.scrollHeight = 30;
      }
      this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + "px";
    },
    // 페이징
    setPaging: function () {
      this.$refs.Paginate.setPaging(
        this.settlement_info.total_count, // 전체 총 개수
        this.search_param.limit, // 몇개 노출 할지
        this.settlement_info.total_count, // 실제 조회된 개수
        this.settlement_info.total_count // 조회된 총 개수
      );
    },
    // 정산확정 사용설정 노출
    showConfirmationUseStatus: function () {
      this.is_show = true;
      this.$refs.ConfirmationUseStatus.show();
    },
    // 정산주기 클릭시 컬러 표시
    settlementCycleChange: function (index) {
      for (let search_date_btn of this.settlement_cycle_class) {
        search_date_btn.selected = false;
      }
      this.settlement_cycle_class[index].selected = true;
      this.search_param.settlement_cycle = this.settlement_cycle_type[index].type;
    },
    // 공급사 리스트 조회
    getSuppliers: async function () {
      return await this.$store.dispatch("common/getSuppliers");
    },

    // 공급사 리스트 입력
    setSuppliers: function (list) {
      let _supplier_list = [];
      for (const supplier of list) {
        if (supplier.supplier_code != "S0000000") {
          _supplier_list.push(supplier);
        }
        if (supplier.user_id == sessionStorage.getItem("supply_user_id")) {
          sessionStorage.setItem("supply_supplier_code", supplier.supplier_code);
          sessionStorage.removeItem("supply_supplier_name");
          sessionStorage.setItem("supply_supplier_name", supplier.supplier_name);
        }
        if (supplier.user_id) {
          this.supplier_list.push(supplier);
        }
      }
      this.supplier_list.sort(function(a, b) {  
        return a.supplier_name < b.supplier_name ? -1 : a.supplier_name > b.supplier_name ? 1 : 0;
      });
      localStorage.setItem("supply_supplier_list", JSON.stringify(_supplier_list));
    },

    // 정산 예정 내역 조회
    getSettlement: async function () {
      let supplier_code_list = [];
      let user_id_list = this.textarea_user_text.split(",");
      for (const user_id of user_id_list) {

        let find = this.supplier_list.find((e) => e.user_id == user_id.trim());
        if (find) {
          supplier_code_list.push(find.supplier_code);
        }
        
      }
      this.search_param.supplier_code = supplier_code_list.join(",");
      if (this.user_type == "S") {
        this.search_param.supplier_code = sessionStorage.getItem("supply_supplier_code");
      }
      return await this.$store.dispatch("supply/getSettlement", this.search_param);
    },

    getSettlementSummary: async function () {
      let supplier_code_list = [];
      let user_id_list = this.textarea_user_text.split(",");
      for (const user_id of user_id_list) {
        let find = this.supplier_list.find((e) => e.user_id == user_id.trim());
        if (find) {
          supplier_code_list.push(find.supplier_code);
        }
      }
      this.search_param.supplier_code = supplier_code_list.join(",");
      if (this.user_type == "S") {
        this.search_param.supplier_code = sessionStorage.getItem("supply_supplier_code");
      }
      return await this.$store.dispatch("supply/getSettlementSummary", this.search_param);
    },

    // 정산 예정 내역 입력
    setSettlement: async function (params) {
      let list = [];
      for (let param of params.list) {
        // 정산예정일이 오늘보다 크면 체크박스 미노출
        param.is_disabled = true;
        if (Number(dayjs(param.settlement_schedule_at).format("YYYYMMDD")) <= this.today) {
          param.is_disabled = false;
        }
        param.is_show = Number(dayjs(param.settlement_schedule_at).format("YYYYMMDD"));
        list.push(param);
      }
      this.settlement_info = params;
      this.settlement_info.list = list;
    },
    // 정산 예정 내역 조회/입력
    searchSettlement: async function () {
      // select_supplier
      let query = {};
      if (this.search_param.settlement_cycle) {
        query = Object.assign({}, query, { settlement_cycle: this.search_param.settlement_cycle });
      }
      if (this.search_param.supplier_code) {
        query = Object.assign({}, query, { supplier_code: this.search_param.supplier_code });
      }
      if (this.search_param.limit) {
        query = Object.assign({}, query, { limit: this.search_param.limit });
      }
      if (this.textarea_user_text) {
        query = Object.assign({}, query, { textarea_user_text: this.textarea_user_text });
      }

      query = Object.assign({}, query, { page_no: 1 });
      this.$router.push({
        query,
      }).catch(async (e) => {
        this.reset();
        // 월간 정산 선택
        this.settlementCycleChange(0);
        await this.setQuery();
        this.$parent.$parent.$refs.LeftMenu.changeSelectMenu("schedule");
        this.today = Number(dayjs().format("YYYYMMDD"));


        let supply_user_id = sessionStorage.getItem("supply_user_id");
        let supply_supplier_code = sessionStorage.getItem("supply_supplier_code");
        if (supply_user_id && !supply_supplier_code) {
          const result = await this.getSuppliers();
          if (result && result.list) {
            await this.setSuppliers(result.list);
          }
        }
    
        if (this.user_type == "P") {
          if (!sessionStorage.getItem("supply_agree_status")) {
            // 이용약관 동의 조회
            let agree = await this.getAgree();
            sessionStorage.setItem("supply_agree_status", agree.agree_status);
            if (agree.agree_status == "disagree") {
              this.$refs.Agree.show();
            }
          } else {
            if (sessionStorage.getItem("supply_agree_status") == "disagree") {
              this.$refs.Agree.show();
            }
          }
        }
        // this.setSuppliers();
    
        // 정산내역 조회
        this.total_settlement_info = await this.getSettlementSummary();
    
        let settlement_result = await this.getSettlement();
        await this.setSettlement(settlement_result);
        let result = await this.getSettlementConfirmationUse();
        this.settlement_confirmation_use = result.status;
        this.setListNumber();
        this.setPaging();

        
      });
    },
    // 공급사 검색 추가
    addSupplier: function () {
      if (!this.select_supplier) {
        alert("공급사를 선택해주세요.");
        return;
      }
      const index = this.textarea_user_text.indexOf(this.select_supplier);
      if (index > -1) {
        alert("이미 추가된 공급사 입니다.");
        return;
      }
      let add_comma = "";
      if (this.textarea_user_text.length > 0) {
        add_comma = ", ";
      }
      this.textarea_user_text += add_comma + this.select_supplier;
    },
    textareaReset: function () {
      this.textarea_user_text = "";
    },
    comma: function (number) {
      if (!number || number == 0) {
        return 0;
      }
      number = Math.floor(Number(number));
      return number.toLocaleString();
    },
    limitChange: async function () {
      this.page_no = 1;
      await this.searchSettlement();
    },
    setListNumber: function () {
      if (this.settlement_info.total_count > this.search_param.limit) {
        this.list_number =
          this.settlement_info.total_count - (this.page_no - 1) * this.search_param.limit;
      } else {
        this.list_number = this.settlement_info.total_count;
      }
    },
    getSettlementCycle: function (cylce) {
      if (cylce == "day") {
        return "일일정산";
      }
      if (cylce == "week") {
        return "주간정산";
      }
      if (cylce == "month") {
        return "월간정산";
      }
    },
    allCheck: function () {
      for (let settlement of this.settlement_info.list) {
        if (settlement.is_disabled == false) {
          settlement.is_check = this.all_check;
        }
      }
    },
    // 정산확정 사용설정 조회
    getSettlementConfirmationUse: async function () {
      return await this.$store.dispatch("supply/getSettlementConfirmationUse");
    },
    // 정산 확정 변경
    setSettlementConfirmation: async function () {
      let list = [];
      for (const settlement of this.settlement_info.list) {
        if (settlement.is_check) {
          list.push({
            settlement_schedule_at: settlement.settlement_schedule_at,
            supplier_code: settlement.supplier_code,
          });
        }
      }

      if (list.length == 0) {
        alert("선택된 정산예정 내역이 없습니다.");
        this.all_check = false;
        return;
      }
      await this.$store.dispatch("supply/setSettlementConfirmation", list);
      alert("정산완료 변경되었습니다.");
      this.page_no = 1;
      await this.searchSettlement();
    },
    //
    excelDownload: async function () {
      if (this.excel_loading == true) {
        alert("잠시만 기다려주세요.");
        return;
      }
      if (!confirm("검색 결과에 있는 내용을 엑셀 다운로드 합니다.\n검색 결과가 많은 경우 시간이 소요될 수 있어 조금만 기다려 주세요.\n확인 버튼을 클릭하시면 엑셀 다운로드 받을 수 있습니다.")) {
        return;
      }
      this.excel_data.total_count = 0;
      this.excel_data.offset = 0;
      let excel_payload = Object.assign({}, this.search_param);
      excel_payload["limit"] = this.excel_data.limit;
      excel_payload["offset"] = 0;

      this.excel_loading = true;

      let result = await this.$store.dispatch("supply/getSettlement", excel_payload);
      if (result.list.length === 0) {
        alert("엑셀 다운로드할 데이터가 없습니다.");
        this.excel_loading = false;
        return false;
      }
      this.excel_data.total_count = result.total_count;
      if (this.excel_data.total_count > this.excel_data.total_limit) {
        alert(
          "엑셀 다운로드 건 수는 " +
            this.excel_data.total_limit.toLocaleString("ko-KR") +
            "건</br>이내로 검색하여 다운로드 해주세요."
        );
        this.excel_loading = false;
        return false;
      }

      let excel_data_array = [];
      for (const settlement of result.list) {
        let excel_datas = {
          supplier_name: settlement.supplier_name,
          supplier_code: settlement.supplier_code,
          settlement_cycle: this.getSettlementCycle(settlement.settlement_cycle),
          settlement_schedule_at: settlement.settlement_schedule_at,
          sale_price: this.comma(settlement.sale_price),
          settlement_amount: this.comma(settlement.settlement_amount),
          commission_amount: this.comma(settlement.commission_amount),
          shipping_fee: this.comma(settlement.shipping_fee),
          regional_surcharge_amount: this.comma(settlement.regional_surcharge_amount),
          individual_shipping_fee: this.comma(settlement.individual_shipping_fee),
          additional_shipping_fee: this.comma(settlement.additional_shipping_fee),
          penalty_amount: this.comma(settlement.penalty_amount),
          deduction_amount: this.comma(settlement.deduction_amount),
          refund_amount: this.comma(settlement.refund_amount),
          settlement_defer_amount: this.comma(settlement.settlement_defer_amount),
        };
        excel_data_array.push(excel_datas);
      }
      this.excel_data.sheetData = excel_data_array;
      this.initExcelDownload();
    },
    /**
     * 엑셀 시작
     */
    initExcelDownload: function () {
      if (this.user_type == "S") {
        delete this.excel_data.sheetHeader.supplier_name;
        delete this.excel_data.sheetHeader.supplier_code;
      }

      this.excel_data.Excel = new Excel(null, this.excel_data.sheetHeader);
      this.addExcel();
    },
    /**
     * 엑셀 추가 데이터
     */
    getExcelDataAdd: async function () {
      this.excel_data.offset = this.excel_data.offset + this.excel_data.limit;
      if (this.excel_data.offset > this.excel_data.total_count) {
        await this.endExcel();
        return false;
      }
      let excel_payload = Object.assign({}, this.search_param);
      excel_payload["limit"] = this.excel_data.limit;
      excel_payload["offset"] = this.excel_data.offset;

      let result = await this.$store.dispatch("supply/getSettlement", excel_payload);
      if (result.list.length === 0) {
        await this.endExcel();
        return false;
      }
      let excel_data_array = [];
      for (const settlement of result.list) {
        let excel_datas = {
          supplier_name: settlement.supplier_name,
          supplier_code: settlement.supplier_code,
          settlement_cycle: this.getSettlementCycle(settlement.settlement_cycle),
          settlement_schedule_at: settlement.settlement_schedule_at,
          sale_price: this.comma(settlement.sale_price),
          settlement_amount: this.comma(settlement.settlement_amount),
          commission_amount: this.comma(settlement.commission_amount),
          shipping_fee: this.comma(settlement.shipping_fee),
          regional_surcharge_amount: this.comma(settlement.regional_surcharge_amount),
          individual_shipping_fee: this.comma(settlement.individual_shipping_fee),
          additional_shipping_fee: this.comma(settlement.additional_shipping_fee),
          penalty_amount: this.comma(settlement.penalty_amount),
          deduction_amount: this.comma(settlement.deduction_amount),
          refund_amount: this.comma(settlement.refund_amount),
          settlement_defer_amount: this.comma(settlement.settlement_defer_amount),
        };
        excel_data_array.push(excel_datas);
      }
      this.excel_data.sheetData = excel_data_array;
      this.excel_data.Excel.addSheet(this.excel_data.sheetData);
      this.addExcel();
    },
    /**
     * 시트에 엑셀 추가
     */
    addExcel: async function () {
      if (this.excel_data.offset === 0) {
        await this.excel_data.Excel.initSheet();
        await this.excel_data.Excel.addSheet(this.excel_data.sheetData);
        await this.getExcelDataAdd();
      } else {
        await this.getExcelDataAdd();
      }
    },
    /**
     * 파일 다운
     */
    endExcel: async function () {
      await this.excel_data.Excel.endSheet(`정산예정_내역_${dayjs().format("YYYY-MM-DD")}`);
      this.excel_loading = false;
    },
    moveCompletion: function (params) {
      this.$parent.$parent.$refs.LeftMenu.movePage("detail");
      const from = dayjs(params.settlement_schedule_at).format("YYYY-MM-DD");
      const to = dayjs(params.settlement_schedule_at).format("YYYY-MM-DD");
      const supply_supplier_list = JSON.parse(localStorage.getItem("supply_supplier_list"));
      const find = supply_supplier_list.find((e) => e.supplier_code == params.supplier_code);
      let textarea_user_text = "";
      if (find) {
        textarea_user_text = find.user_id;
      }
      
      this.$router.push({
        name: "detail",
        query: {
          textarea_user_text,
          settlement_cycle: params.settlement_cycle,
          from,
          to,
          settlement_status: "schedule",
          date_search_type: "schedule",
        },
      });
    },
    // 이용약관 동의 조회
    getAgree: async function () {
      return await this.$store.dispatch("common/getAgree");
    },
    tooltipToogle: function() {
      this.is_modal_show = !this.is_modal_show;
    }
  },

  components: {
    Paginate,
    ConfirmationUseStatus,
    Agree,
  },
  filters: Filters,
};
