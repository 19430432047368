import Vue from "vue";
import VueRouter from "vue-router";
import Schedule from "../views/schedule/Schedule.vue";
import Completion from "../views/completion/Completion.vue";
import Detail from "../views/detail/Detail.vue";
import Write from "../views/write/Write.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: {
      name: "schedule",
    },
  },
  {
    path: "/supply",
    redirect: {
      name: "schedule",
    },
  },
  {
    path: "/supply/schedule",
    name: "schedule",
    component: Schedule,
  },
  {
    path: "/supply/completion",
    name: "completion",
    component: Completion,
  },
  {
    path: "/supply/detail",
    name: "detail",
    component: Detail,
  },
  {
    path: "/supply/write",
    name: "write",
    component: Write,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
