import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import dayjs from "dayjs";
import Paginate from "@/components/common/Paginate.vue";
import Filters from "@/libs/filters.js";
import OrderDetail from "@/components/modal/OrderDetail.vue";
import OrderHistory from "@/components/modal/OrderHistory.vue";
import ClaimCode from "@/components/modal/ClaimCode.vue";
import CollectionDetail from "@/components/modal/CollectionDetail.vue";
import Excel from "@/libs/excel.js";

export default {
  name: "Detail",
  data: () => ({
    search_date_btn_class: [
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
    ],
    lang: {
      formatLocale: {
        months: [
          "1월",
          "2월",
          "3월",
          "4월",
          "5월",
          "6월",
          "7월",
          "8월",
          "9월",
          "10월",
          "11월",
          "12월",
        ],
        monthsShort: [
          "1월",
          "2월",
          "3월",
          "4월",
          "5월",
          "6월",
          "7월",
          "8월",
          "9월",
          "10월",
          "11월",
          "12월",
        ],
        weekdays: ["일요일", "월요알", "화요일", "수요일", "목요일", "금요일", "토요일"],
        weekdaysShort: ["일", "월", "화", "수", "목", "금", "토"],
        weekdaysMin: ["일", "월", "화", "수", "목", "금", "토"],
        firstDayOfWeek: 0,
        firstWeekContainsDate: 1,
      },
    },
    search_param: {
      from: null,
      to: null,
      offset: 0,
      limit: 20,
      search_tpye: "order_id",
      search_text: "",
      settlement_category: "",
      order_status: "",
      settlement_status: "",
      order_id: "",
      order_item_code: "",
      settlement_no: "",
      product_name: "",
      settlement_category: "",
      order_status: "",
      settlement_status: "",
      supplier_code: "",
      date_search_type: "completion",
      select_day: null,
      order_by: "delivered_desc",
    },
    settlement_count: 60,
    limit: 20,
    settlement_list: [0, 1, 2, 3],
    page_no: 1,
    is_show: false,
    is_sub_show: false,
    user_type: sessionStorage.getItem("user_type"),
    settlement_status: "",
    supplier_list: [],
    select_supplier: "",
    textarea_user_text: "",
    total_settlement_info: {
      total_sale_price: 0,
      total_settlement_amount: 0,
      total_commission_amount: 0,
      total_shipping_fee: 0,
      total_regional_surcharge_amount: 0,
      total_individual_shipping_fee: 0,
      total_additional_shipping_fee: 0,
      total_penalty_amount: 0,
      total_deduction_amount: 0,
      total_refund_amount: 0,
      total_settlement_defer_amount: 0,
    },
    settlement_info: {
      total_count: 0,
      list: [],
    },
    list_number: 0,
    excel_data: {
      Excel: null,
      total_limit: 200000,
      limit: 10000,
      total_count: 0,
      offset: 0,
      sheetData: [],
      sheetHeader: {
        supplier_name: "공급사명",
        supplier_code: "공급사 코드",
        settlement_no: "정산번호",
        order_id: "주문번호",
        order_item_code: "품목주문번호",
        exchange_no: "교환번호",
        return_no: "반품번호",
        order_status: "주문상태",
        settlement_category: "정산항목",
        product_name: "상품명",
        option_name: "옵션명",
        quantity: "수량",
        sale_price: "판매가",
        sale_price_sum: "판매가 합계",
        settlement_amount: "정산금액",
        shipping_fee: "기본배송비",
        regional_surcharge_amount: "지역별 배송비",
        individual_shipping_fee: "개별 배송비",
        commission_amount: "수수료 금액",
        commission_percent: "수수료율%",
        penalty_amount: "패널티 금액",
        deduction_amount: "공제 금액",
        refund_amount: "환급 금액",
        settlement_defer_amount: "정산 보류 금액",
        exchang_at: "교환완료일",
        return_at: "반품완료일",
        normal_at: "배송완료일",
        settlement_schedule_at: "정산예정일",
        settlement_completion_at: "정산완료일",
        payment_method: "결제수단",
        payment_company: "결제업체",
        card_payment_info: "카드결제정보",
      },
    },
    excel_loading: false,
    all_check: false,
    disabled_order_status: false,
  }),

  created: function () {
    this.search_param.from = dayjs().format("YYYY-MM-DD");
    this.search_param.to = dayjs().format("YYYY-MM-DD");
  },

  mounted: async function () {
    this.setSuppliers();

    this.autoInputQuery();
    // 정산내역 조회
    this.total_settlement_info = await this.getSettlementSummary();
    const result = await this.getSettlementDetailList();
    this.setSettlementDetailList(result);
    this.setListNumber();
    this.setPaging();
    this.$parent.$parent.$refs.LeftMenu.changeSelectMenu("detail");
  },

  methods: {
    reset: function () {
      Object.assign(this.$data, this.$options.data());
    },
    autoInputQuery: function () {
      if (this.$route.query) {
        // 검색 기간 > 입력
        if (this.$route.query.settlement_cycle) {
          //
        } else {
          if (!this.$route.query.from && !this.$route.query.to) {
            this.searchDateChange(3, 1, "month");
          }
        }

        if (this.$route.query.from) {
          this.search_param.from = this.$route.query.from;
        }

        if (this.$route.query.to) {
          this.search_param.to = this.$route.query.to;
        }


        // 공급사 > 입력 supplier_code
        if (this.$route.query.supplier_code) {
          let supplier_code_list = this.$route.query.supplier_code.split(",");
          for (const supplier_code of supplier_code_list) {
            const find = this.supplier_list.find((e) => e.supplier_code == supplier_code);

            let add_comma = "";
            if (this.textarea_user_text.length > 0) {
              add_comma = ", ";
            }
            if (find) {
              this.textarea_user_text += add_comma + find.supplier_name;
            }
          }
        }

        // 정산완료 여부 > 입력
        if (this.$route.query.settlement_status) {
          this.search_param.settlement_status = this.$route.query.settlement_status;
        }

        // limit > 입력
        if (this.$route.query.limit) {
          this.search_param.limit = this.$route.query.limit;
        }

        // 검색어 > 선택
        if (this.$route.query.search_tpye) {
          this.search_param.search_tpye = this.$route.query.search_tpye;
        }

        // 검색어 > 입력
        if (this.$route.query.search_text) {
          this.search_param.search_text = this.$route.query.search_text;
        }

        // 정산항목 > 선택
        if (this.$route.query.settlement_category) {
          this.search_param.settlement_category = this.$route.query.settlement_category;
        }

        // 주문상태 > 선택
        if (this.$route.query.order_status) {
          this.search_param.order_status = this.$route.query.order_status;
        }

        // 공급사 입력
        if (this.$route.query.textarea_user_text) {
          this.textarea_user_text = this.$route.query.textarea_user_text;
        }


        if (this.search_param.search_tpye && this.search_param.search_text) {
          this.search_param[this.search_param.search_tpye] = this.search_param.search_text;
        }

        if (this.$route.query.page_no) {
          this.page_no = this.$route.query.page_no;
          this.search_param.offset = (this.page_no - 1) * this.search_param.limit
        }

        if (this.$route.query.date_search_type) {
          this.search_param.date_search_type = this.$route.query.date_search_type;
          const date_search_type_array = [
            "delivered",
            "refund",
            "exchange"
          ];
          if (date_search_type_array.indexOf(this.$route.query.date_search_type) > -1) {
            this.disabled_order_status = true;
          }
        }

        if (this.$route.query.select_day || this.$route.query.select_day === 0) {
          this.search_param.select_day = this.$route.query.select_day;
          if (this.search_param.select_day == 0) {
            this.searchDateChange(0, 0, 'day');
          }
          if (this.search_param.select_day == 1) {
            this.searchDateChange(1, 3, 'day');
          }
          if (this.search_param.select_day == 2) {
            this.searchDateChange(2, 7, 'day');
          }
          if (this.search_param.select_day == 3) {
            this.searchDateChange(3, 1, 'month');
          }
          if (this.search_param.select_day == 4) {
            this.searchDateChange(4, 3, 'month');
          }
          if (this.search_param.select_day == 5) {
            this.searchDateChange(5, 1, 'year');
          }
        }
        if (this.$route.query.order_by) {
          this.search_param.order_by = this.$route.query.order_by;
        }
      }
    },
    // 공급사 리스트 조회
    getSuppliers: async function () {
      return await this.$store.dispatch("common/getSuppliers");
    },
    // 공급사 리스트 입력
    setSuppliers: function () {
      this.supplier_list = [];
      const supply_supplier_list = JSON.parse(localStorage.getItem("supply_supplier_list"));
      for (const supplier of supply_supplier_list) {
        if (supplier.supplier_code == "S0000000") {
          continue;
        }
        if (supplier.user_id) {
          this.supplier_list.push(supplier);
        }
      }
      this.supplier_list.sort(function(a, b) {  
        return a.supplier_name < b.supplier_name ? -1 : a.supplier_name > b.supplier_name ? 1 : 0;
      });
    },
    // 공급사 검색 추가
    addSupplier: function () {
      if (!this.select_supplier) {
        alert("공급사를 선택해주세요.");
        return;
      }
      const index = this.textarea_user_text.indexOf(this.select_supplier);
      if (index > -1) {
        alert("이미 추가된 공급사 입니다.");
        return;
      }
      let add_comma = "";
      if (this.textarea_user_text.length > 0) {
        add_comma = ", ";
      }
      this.textarea_user_text += add_comma + this.select_supplier;
    },
    disabledStartDate: function (date) {
      return date > dayjs(this.search_param.to);
    },
    disabledEndDate: function (date) {
      return date < dayjs(this.search_param.from);
    },
    startDateCheck: function () {
      let result = true;
      try {
        let date = this.search_param.from.split("-");
        let y = parseInt(date[0], 10),
          m = parseInt(date[1], 10),
          d = parseInt(date[2], 10);

        let dateRegex =
          /^(?=\d)(?:(?:31(?!.(?:0?[2469]|11))|(?:30|29)(?!.0?2)|29(?=.0?2.(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(?:\x20|$))|(?:2[0-8]|1\d|0?[1-9]))([-.\/])(?:1[012]|0?[1-9])\1(?:1[6-9]|[2-9]\d)?\d\d(?:(?=\x20\d)\x20|$))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\x20[AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;
        result = dateRegex.test(d + "-" + m + "-" + y);
      } catch (err) {
        result = false;
      }
      if (result === false) {
        alert("날짜를 확인해주세요.");
        this.search_param.from = dayjs().format("YYYY-MM-DD");
      }
    },
    endDateCheck: function () {
      let result = true;
      try {
        let date = this.search_param.to.split("-");
        let y = parseInt(date[0], 10),
          m = parseInt(date[1], 10),
          d = parseInt(date[2], 10);

        let dateRegex =
          /^(?=\d)(?:(?:31(?!.(?:0?[2469]|11))|(?:30|29)(?!.0?2)|29(?=.0?2.(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(?:\x20|$))|(?:2[0-8]|1\d|0?[1-9]))([-.\/])(?:1[012]|0?[1-9])\1(?:1[6-9]|[2-9]\d)?\d\d(?:(?=\x20\d)\x20|$))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\x20[AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;
        result = dateRegex.test(d + "-" + m + "-" + y);
      } catch (err) {
        result = false;
      }
      if (result === false) {
        alert("날짜를 확인해주세요.");
        this.search_param.to = dayjs().format("YYYY-MM-DD");
      }
    },
    texareaInput: function (e) {
      this.$refs.textarea.style.height = "auto";
      if (this.$refs.textarea.scrollHeight < 30) {
        this.$refs.textarea.scrollHeight = 30;
      }
      this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + "px";
    },
    setPaging: function () {
      this.$refs.Paginate.setPaging(
        this.settlement_info.total_count, // 전체 총 개수
        this.search_param.limit, // 몇개 노출 할지
        this.settlement_info.total_count, // 실제 조회된 개수
        this.settlement_info.total_count // 조회된 총 개수
      );
    },
    showOrderDetail: function () {
      this.is_show = true;
      this.$refs.OrderDetail.show();
    },
    showOrderHistory: function () {
      this.is_sub_show = true;
      this.$refs.OrderHistory.show();
    },
    showClaimCode: function () {
      this.is_sub_show = true;
      this.$refs.ClaimCode.show();
    },
    showCollectionDetail: function (settlement_no) {
      this.is_sub_show = true;
      this.$refs.CollectionDetail.show(settlement_no);
    },
    searchDateChange: function (index, number, type) {
      this.search_param.select_day = index;
      for (let search_date_btn of this.search_date_btn_class) {
        search_date_btn.selected = false;
      }
      this.search_date_btn_class[index].selected = true;
      this.search_date_btn_class[index].selected = true;
      let from = dayjs().subtract(number, type).format("YYYY-MM-DD");
      let to = dayjs().format("YYYY-MM-DD");

      
      if (this.search_param.date_search_type == "schedule") {
        // 정산예정일
        from = dayjs().format("YYYY-MM-DD");
        to = dayjs().add(number, type).format("YYYY-MM-DD");

      }

      this.search_param.from = from;
      this.search_param.to = to;
    },
    textareaReset: function () {
      this.textarea_user_text = "";
    },
    // 정산 예정 내역 조회/입력
    searchSettlement: async function () {
      let query = {};
      if (this.search_param.from) {
        query = Object.assign({}, query, { from: this.search_param.from });
      }
      if (this.search_param.to) {
        query = Object.assign({}, query, { to: this.search_param.to });
      }
      if (this.search_param.limit) {
        query = Object.assign({}, query, { limit: this.search_param.limit });
      }
      if (this.search_param.search_tpye) {
        query = Object.assign({}, query, { search_tpye: this.search_param.search_tpye });
      }
      if (this.search_param.search_text) {
        query = Object.assign({}, query, { search_text: this.search_param.search_text.trim() });
      }
      if (this.search_param.settlement_category) {
        query = Object.assign({}, query, {
          settlement_category: this.search_param.settlement_category,
        });
      }
      if (this.search_param.order_status) {
        query = Object.assign({}, query, { order_status: this.search_param.order_status });
      }
      if (this.search_param.settlement_status) {
        query = Object.assign({}, query, {
          settlement_status: this.search_param.settlement_status,
        });
      }
      if (this.textarea_user_text) {
        query = Object.assign({}, query, { textarea_user_text: this.textarea_user_text.trim() });
      }
      if (this.search_param.date_search_type) {
        query = Object.assign({}, query, {
          date_search_type: this.search_param.date_search_type,
        });
      }

      if (this.search_param.select_day || this.search_param.select_day == 0) {
        query = Object.assign({}, query, {
          select_day: this.search_param.select_day,
        });
      }

      query = Object.assign({}, query, {
        order_by: this.search_param.order_by,
      });

      query = Object.assign({}, query, { page_no: 1 });
      this.$router.push({
        name: "detail",
        query,
      }).catch(async (e) => {
        // 정산내역 조회
        this.reset();
        this.search_param.from = dayjs().format("YYYY-MM-DD");
        this.search_param.to = dayjs().format("YYYY-MM-DD");
        this.setSuppliers();
        this.autoInputQuery();
        this.total_settlement_info = await this.getSettlementSummary();
        const result = await this.getSettlementDetailList();
        this.setSettlementDetailList(result);
        this.setListNumber();
        this.setPaging();
        this.$parent.$parent.$refs.LeftMenu.changeSelectMenu("detail");
      });
    },
    // 정산상세 내역 조회
    getSettlementDetailList: async function () {
      let supplier_code_list = [];
      let user_id_list = this.textarea_user_text.split(",");
      for (const user_id of user_id_list) {
        const find = this.supplier_list.find((e) => e.user_id == user_id.trim());
        if (find) {
          supplier_code_list.push(find.supplier_code);
        }
      }
      this.search_param.supplier_code = supplier_code_list.join(",");
      if (this.user_type == "S") {
        this.search_param.supplier_code = sessionStorage.getItem("supply_supplier_code");
      }
      return await this.$store.dispatch("supply/getSettlementDetailList", this.search_param);
    },
    // 정산상세 내역 입력
    setSettlementDetailList: async function (params) {
      let list = [];
      for (let param of params.list) {
        param.is_check = false;
        list.push(param);
      }
      this.settlement_info = params;
      this.settlement_info.list = list;
    },
    comma: function (number) {
      if (!number || number == 0) {
        return "0";
      }
      number = Math.floor(Number(number));
      return number.toLocaleString();
    },
    limitChange: async function () {
      this.page_no = 1;
      await this.searchSettlement();
    },
    setListNumber: function () {
      if (this.settlement_info.total_count > this.search_param.limit) {
        this.list_number =
          this.settlement_info.total_count - ((this.page_no - 1) * this.search_param.limit);
      } else {
        this.list_number = this.settlement_info.total_count;
      }
    },
    getOrderStatus: function (value) {
      if (value == "normal") {
        return "정상";
      }
      if (value == "cancel") {
        return "취소";
      }
      if (value == "return") {
        return "반품";
      }
      if (value == "exchange") {
        return "교환";
      }
    },
    getSettlementCategory: function (value) {
      if (value == "product") {
        return "주문상품";
      }
      if (value == "penalty") {
        return "패널티";
      }
      if (value == "deduction") {
        return "공제";
      }
      if (value == "refund") {
        return "환급";
      }
    },
    excelDownload: async function () {
      if (this.excel_loading == true) {
        alert("잠시만 기다려주세요.");
        return;
      }
      if (!confirm("검색 결과에 있는 내용을 엑셀 다운로드 합니다.\n검색 결과가 많은 경우 시간이 소요될 수 있어 조금만 기다려 주세요.\n확인 버튼을 클릭하시면 엑셀 다운로드 받을 수 있습니다.")) {
        return;
      }

      this.excel_data.total_count = 0;
      this.excel_data.offset = 0;
      let excel_payload = Object.assign({}, this.search_param);
      excel_payload["limit"] = this.excel_data.limit;
      excel_payload["offset"] = 0;

      this.excel_loading = true;

      let result = await this.$store.dispatch("supply/getSettlementDetailList", excel_payload);
      if (result.list.length === 0) {
        alert("엑셀 다운로드할 데이터가 없습니다.");
        this.excel_loading = false;
        return false;
      }
      this.excel_data.total_count = result.total_count;
      if (this.excel_data.total_count > this.excel_data.total_limit) {
        alert(
          "엑셀 다운로드 건 수는 " +
            this.excel_data.total_limit.toLocaleString("ko-KR") +
            "건\n이내로 검색하여 다운로드 해주세요."
        );
        this.excel_loading = false;
        return false;
      }

      let excel_data_array = [];
      for (const settlement of result.list) {
        let order_id = settlement.order_id;
        if (settlement.order_id == "manual") {
          order_id = "";
        }
        let order_item_code = settlement.order_item_code;
        if (settlement.order_item_code == "manual") {
          order_item_code = "";
        }

        let product_name = settlement.product_name;
        if (settlement.is_write == 'enabled') {
          product_name = settlement.reason;
        }

        let excel_datas = {
          supplier_name: settlement.supplier_name,
          supplier_code: settlement.supplier_code,
          settlement_no: settlement.settlement_no,
          order_id,
          order_item_code,
          exchange_no: settlement.exchange_no,
          return_no: settlement.return_no,
          order_status: this.getOrderStatus(settlement.order_status),
          settlement_category: this.getSettlementCategory(settlement.settlement_category),
          product_name,
          option_name: settlement.option_name,
          quantity: this.comma(settlement.quantity),
          sale_price: this.comma(settlement.sale_price),
          sale_price_sum: this.comma(settlement.sale_price * settlement.quantity),
          settlement_amount: this.comma(settlement.settlement_amount),
          shipping_fee: this.comma(settlement.shipping_fee),
          regional_surcharge_amount: this.comma(settlement.regional_surcharge_amount),
          individual_shipping_fee: this.comma(settlement.individual_shipping_fee),
          additional_shipping_fee: this.comma(settlement.additional_shipping_fee),
          commission_amount: this.comma(settlement.commission_amount),
          commission_percent: this.comma(settlement.commission_percent),
          penalty_amount: this.comma(settlement.penalty_amount),
          deduction_amount: this.comma(settlement.deduction_amount),
          refund_amount: this.comma(settlement.refund_amount),
          settlement_defer_amount: this.comma(settlement.settlement_defer_amount),

          exchang_at: settlement.order_status == "exchange" ? this.dateCheck(settlement.exchange_date) : "-",
          return_at: settlement.order_status == "return" ? this.dateCheck(settlement.refund_date) : "-",
          normal_at: settlement.order_status == "normal" ? this.dateCheck(settlement.delivered_date) : "-",

          settlement_schedule_at: this.dateFormat(settlement.settlement_schedule_at),
          settlement_completion_at: this.dateFormat(settlement.settlement_completion_at),
          payment_method: settlement.payment_method,
          payment_company: settlement.payment_company,
          card_payment_info: settlement.card_payment_info,
        };
        excel_data_array.push(excel_datas);
      }
      this.excel_data.sheetData = excel_data_array;
      this.initExcelDownload();
    },
    /**
     * 엑셀 시작
     */
    initExcelDownload: function () {
      if (this.user_type == "S") {
        delete this.excel_data.sheetHeader.supplier_name;
        delete this.excel_data.sheetHeader.supplier_code;
      }

      this.excel_data.Excel = new Excel(null, this.excel_data.sheetHeader);
      this.addExcel();
    },
    /**
     * 엑셀 추가 데이터
     */
    getExcelDataAdd: async function () {
      this.excel_data.offset = this.excel_data.offset + this.excel_data.limit;
      if (this.excel_data.offset > this.excel_data.total_count) {
        await this.endExcel();
        return false;
      }
      let excel_payload = Object.assign({}, this.search_param);
      excel_payload["limit"] = this.excel_data.limit;
      excel_payload["offset"] = this.excel_data.offset;

      let result = await this.$store.dispatch("supply/getSettlementDetailList", excel_payload);
      if (result.list.length === 0) {
        await this.endExcel();
        return false;
      }
      let excel_data_array = [];
      for (const settlement of result.list) {
        let product_name = settlement.product_name;
        if (settlement.is_write == 'enabled') {
          product_name = settlement.reason;
        }
        let excel_datas = {
          supplier_name: settlement.supplier_name,
          supplier_code: settlement.supplier_code,
          settlement_no: settlement.settlement_no,
          order_id: settlement.order_id,
          order_item_code: settlement.order_item_code,
          exchange_no: settlement.exchange_no,
          return_no: settlement.return_no,
          order_status: this.getOrderStatus(settlement.order_status),
          settlement_category: this.getSettlementCategory(settlement.settlement_category),
          product_name,
          option_name: settlement.option_name,
          quantity: this.comma(settlement.quantity),
          sale_price: this.comma(settlement.sale_price),
          sale_price_sum: this.comma(settlement.sale_price * settlement.quantity),
          settlement_amount: this.comma(settlement.settlement_amount),
          shipping_fee: this.comma(settlement.shipping_fee),
          regional_surcharge_amount: this.comma(settlement.regional_surcharge_amount),
          individual_shipping_fee: this.comma(settlement.individual_shipping_fee),
          additional_shipping_fee: this.comma(settlement.additional_shipping_fee),
          commission_amount: this.comma(settlement.commission_amount),
          commission_percent: settlement.commission_percent,
          penalty_amount: this.comma(settlement.penalty_amount),
          deduction_amount: this.comma(settlement.deduction_amount),
          refund_amount: this.comma(settlement.refund_amount),

          settlement_defer_amount: this.comma(settlement.settlement_defer_amount),
          settlement_schedule_at: this.dateFormat(settlement.settlement_schedule_at),
          settlement_completion_at: this.dateFormat(settlement.settlement_completion_at),

          exchang_at: settlement.order_status == "exchange" ? settlement.exchange_date : "-",
          return_at: settlement.order_status == "return" ? settlement.refund_date : "-",
          normal_at: settlement.order_status == "normal" ? settlement.delivered_date : "-",
          
          payment_method: settlement.payment_method,
          payment_company: settlement.payment_company,
          card_payment_info: settlement.card_payment_info,
        };
        excel_data_array.push(excel_datas);
      }
      this.excel_data.sheetData = excel_data_array;
      this.excel_data.Excel.addSheet(this.excel_data.sheetData);
      this.addExcel();
    },
    /**
     * 시트에 엑셀 추가
     */
    addExcel: async function () {
      if (this.excel_data.offset === 0) {
        await this.excel_data.Excel.initSheet();
        await this.excel_data.Excel.addSheet(this.excel_data.sheetData);
        await this.getExcelDataAdd();
      } else {
        await this.getExcelDataAdd();
      }
    },
    /**
     * 파일 다운
     */
    endExcel: async function () {
      await this.excel_data.Excel.endSheet(`정산상세_내역_${dayjs().format("YYYY-MM-DD")}`);
      this.excel_loading = false;
    },
    allCheck: function () {
      for (let settlement of this.settlement_info.list) {
        settlement.is_check = this.all_check;
        if (settlement.is_write == 'enabled') {
          settlement.is_check = false;
        }
      }
    },
    writeMove: function () {
      let relation_order_item_code = [];
      let settlement_schedule_at = null;
      let settlement_completion_at = null;
      let supplier_code = null;
      let supplier_code_list = [];
      for (let settlement of this.settlement_info.list) {
        if (settlement.is_check == true) {
          const index = supplier_code_list.findIndex((e) => e == settlement.supplier_code);
          if (index < 0) {
            supplier_code_list.push(settlement.supplier_code);
          }
        }
      }
      if (supplier_code_list.length > 1) {
        alert("서로 다른 공급사가 선택되어 있습니다");
        return;
      }
      for (let settlement of this.settlement_info.list) {
        if (settlement.is_check == true) {
          relation_order_item_code.push(settlement.order_item_code);
          settlement_schedule_at = settlement.settlement_schedule_at
            ? this.dateFormat(settlement.settlement_schedule_at)
            : null;
          settlement_completion_at = settlement.settlement_completion_at
            ? settlement.settlement_completion_at
            : null;
          supplier_code = settlement.supplier_code;
        }
      }
      if (relation_order_item_code.length == 0) {
        alert("선택된 항목이 없습니다.");
        return;
      }
      this.$router.push({
        name: "write",
        query: {
          settlement_schedule_at,
          settlement_completion_at,
          relation_order_item_code: relation_order_item_code.join(","),
          supplier_code,
        },
      });
    },
    orderView: function (order_id) {
      const mall_id = sessionStorage.getItem("supply_mall_id");
      window.open(
        `https://${mall_id}.cafe24.com/admin/php/s_new/order_detail.php?order_id=` +
          order_id,
        "_blank"
      );
    },
    productView: function (product_no) {
      const mall_id = sessionStorage.getItem("supply_mall_id");
      window.open(
        `https://${mall_id}.cafe24.com/disp/admin/shop1/product/ProductRegister?product_no=` +
        product_no,
        "_blank"
      );
    },
    settlementDefer: async function (settlement_no, type) {
      const params = {
        type,
        settlement_no_list: [settlement_no],
      };

      if (type == "pending") {
        let result = await this.$store.dispatch("supply/setSettlementPending", params);
        if (result?.settlement_no_list.length > 0) {
          await this.searchSettlement();
          alert("정산보류 되었습니다.");
        }
      } else {
        let result = await this.$store.dispatch("supply/setSettlementPrepare", params);
        if (result?.settlement_no_list.length > 0) {
          await this.searchSettlement();
          alert("정산보류 해제 되었습니다.");
        }
      }

    },
    changeSearchDate: function () {
      for (let search_date_btn of this.search_date_btn_class) {
        search_date_btn.selected = false;
      }
      this.search_param.select_day = null;
    },
    getSettlementSummary: async function () {
      let supplier_code_list = [];
      let user_id_list = this.textarea_user_text.split(",");
      for (const user_id of user_id_list) {
        let find = this.supplier_list.find((e) => e.user_id == user_id.trim());
        if (find) {
          supplier_code_list.push(find.supplier_code);
        }
      }
      this.search_param.supplier_code = supplier_code_list.join(",");
      if (this.user_type == "S") {
        this.search_param.supplier_code = sessionStorage.getItem("supply_supplier_code");
      }
      return await this.$store.dispatch("supply/getSettlementSummary", this.search_param);
    },
    dateFormat: function (date) {
      if (!date) {
        return date;
      }
      return dayjs(date).format("YYYY-MM-DD");
    },
    menualCheck: function (param) {
      if (param == "manual") {
        param = "";
      }
      return param;
    },
    dateSearchTypeChange: function () {
      const date_search_type_array = [
        "delivered",
        "refund",
        "exchange"
      ];
      if (date_search_type_array.indexOf(this.search_param.date_search_type) > -1) {
        this.search_param.order_status = "";
        this.disabled_order_status = true;
      } else {
        this.disabled_order_status = false;
      }
    },
    beforDay: function (day) {
      return dayjs(day).subtract(1, "day").format("YYYY-MM-DD");
    },
    orderByChange: async function () {
      this.page_no = 1;
      await this.searchSettlement();
    },
    dateCheck: function(date) {
      if (!date) {
        return "-";
      }
      return date;
    }
  },
  components: {
    DatePicker,
    Paginate,
    OrderDetail,
    OrderHistory,
    ClaimCode,
    CollectionDetail,
  },
  filters: Filters,
};
