<template>
  <div class="components overFlowAuto">
    <div class="headingArea">
      <div class="mTitle">
        
        <div class="ec-base-box typeThin supply_main board_background mt-2">
          <button type="button" class="icon eTip mr-2 cursor-auto">도움말</button>
          <p class="board_background">
            공급사별 입력한 내용과 금액은 <font class="bold ">정산예정인 경우 해당 정산예정일에 반영되며, 정산완료인 경우 다음 정산예정일에 반영</font>됩니다.<br>
            개별 등록한 수기정산요청건은 공급사별 예정된 정산예정일에 반영됩니다.
          </p>
        </div>

        <h1 class="page-title">수기정산 요청</h1>
      </div>
    </div>
    <Write></Write>
  </div>
</template>

<script>
import Write from "@/components/write/Write.vue";

export default {
  name: "HomeView",
  components: {
    Write,
  },
};
</script>
