<template>
  <div class="components overFlowAuto" style="min-width: 1160px;">
    <div class="headingArea">
      <div class="mTitle">

        <div class="ec-base-box typeThin supply_main board_background mt-2">
          <button type="button" class="icon eTip mr-2 cursor-auto">도움말</button>
          <p v-show="user_type == 'P'" class="board_background">
            공급사별 <strong>정산주기별(월간/주간/일일) 정산예정 내역</strong>을 확인할 수 있습니다. <br>
            [카페24 어드민 > 쇼핑몰 설정 > 운영자 설정 > 공급사 관리 > 공급사 계정 관리] 메뉴에서 설정한 공급사별 정산주기에 따라 정산 내역이 생성됩니다.
          </p>
          <p v-show="user_type == 'S'" class="board_background">
            판매사에서 설정한 공급사 정산주기에 따라 정산예정 내역이 생성됩니다.<br>
            정산예정일을 클릭하면 상세내역 확인이 가능합니다.
          </p>
        </div>

        <h1 class="page-title">정산예정 내역</h1>
      </div>
    </div>
    <Schedule></Schedule>
  </div>
</template>

<script>
import Schedule from "@/components/schedule/Schedule.vue";

export default {
  name: "HomeView",
  components: {
    Schedule,
  },
  data: () => ({
    user_type: sessionStorage.getItem("user_type"),
  }),
};
</script>
