<template>
  <div id="app">
    <Header></Header>
    <LeftMenu ref="LeftMenu"></LeftMenu>
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import "./assets/style/admin.css";
import "./assets/style/custom.css";
import "./assets/style/pageing.css";

import Header from "@/components/common/Header.vue";
import LeftMenu from "@/components/common/LeftMenu.vue";
export default {
  name: "app",
  components: {
    Header,
    LeftMenu,
  },
};
</script>
