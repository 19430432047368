import dayjs from "dayjs";
import Filters from "@/libs/filters.js";
export default {
  name: "OrderDetail",
  data: () => ({
    dayjs,
    is_show: false,
    is_sub_show: false,
  }),

  created: function () {
    //
  },


  methods: {
    show: function () {
      this.is_show = true;
    },
    closeModal: function () {
      this.is_show = false;
      this.$parent.is_show = false;
    },
    showOrderHistory: function () {
      this.$parent.showOrderHistory();
    },
    showClaimCode: function () {
      this.$parent.showClaimCode();
    },
  },

  components: {
    //
  },
  filters: Filters,
};
