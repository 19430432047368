import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import dayjs from "dayjs";
import Filters from "@/libs/filters.js";
import Required from "@/assets/images/required.png";

export default {
  name: "Write",
  data: () => ({
    search_date_btn_class: [
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
    ],
    lang: {
      formatLocale: {
        months: [
          "1월",
          "2월",
          "3월",
          "4월",
          "5월",
          "6월",
          "7월",
          "8월",
          "9월",
          "10월",
          "11월",
          "12월",
        ],
        monthsShort: [
          "1월",
          "2월",
          "3월",
          "4월",
          "5월",
          "6월",
          "7월",
          "8월",
          "9월",
          "10월",
          "11월",
          "12월",
        ],
        weekdays: ["일요일", "월요알", "화요일", "수요일", "목요일", "금요일", "토요일"],
        weekdaysShort: ["일", "월", "화", "수", "목", "금", "토"],
        weekdaysMin: ["일", "월", "화", "수", "목", "금", "토"],
        firstDayOfWeek: 0,
        firstWeekContainsDate: 1,
      },
    },
    search_param: {
      from: null,
      to: null,
      offset: 0,
      limit: 20,
    },
    collection_count: 60,
    limit: 20,
    collection_list: [0, 1, 2, 3],
    page_no: 1,
    Required,
    amount: 0,
    reason: null,
    option_list_show: false,
    border_bottom_radius_style: "",
    border_top_radius_style: "",
    relation_order_item_code: "",
    supply_supplier_list: JSON.parse(localStorage.getItem("supply_supplier_list")),
    search_supplier_input: "",
    select_supplier: {
      supplier_code: "",
      supplier_name: "선택",
      user_id: "",
    },
    supply_supplier_setting: JSON.parse(localStorage.getItem("supply_supplier_setting")),
    settlement_schedule_at: "",
    settlement_completion_at: "",
    settlement_category: "penalty",
    settlement_schedule_at_option_list: [],
  }),

  created: async function () {
    this.search_param.from = dayjs().format("YYYY-MM-DD");
    this.search_param.to = dayjs().format("YYYY-MM-DD");

    if (this.$route.query?.relation_order_item_code) {
      this.relation_order_item_code = this.$route.query?.relation_order_item_code;
    }

    if (this.$route.query?.settlement_schedule_at) {
      this.settlement_schedule_at = this.$route.query.settlement_schedule_at;
    }

    if (this.$route.query?.settlement_completion_at) {
      this.settlement_completion_at = this.$route.query?.settlement_completion_at;
    }

    if (this.$route.query?.supplier_code) {
      const find = this.supply_supplier_list.find(
        (e) => e.supplier_code == this.$route.query?.supplier_code
      );
      if (find) {
        this.select_supplier = find;
        this.setSupplierSetting();
        await this.getSettlement(find);
      }
    }
    this.$parent.$parent.$refs.LeftMenu.changeSelectMenu("write");
    
  },

  methods: {
    disabledStartDate: function (date) {
      return date > dayjs(this.search_param.to);
    },
    disabledEndDate: function (date) {
      return date < dayjs(this.search_param.from);
    },
    startDateCheck: function () {
      let result = true;
      try {
        let date = this.search_param.from.split("-");
        let y = parseInt(date[0], 10),
          m = parseInt(date[1], 10),
          d = parseInt(date[2], 10);

        let dateRegex =
          /^(?=\d)(?:(?:31(?!.(?:0?[2469]|11))|(?:30|29)(?!.0?2)|29(?=.0?2.(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(?:\x20|$))|(?:2[0-8]|1\d|0?[1-9]))([-.\/])(?:1[012]|0?[1-9])\1(?:1[6-9]|[2-9]\d)?\d\d(?:(?=\x20\d)\x20|$))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\x20[AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;
        result = dateRegex.test(d + "-" + m + "-" + y);
      } catch (err) {
        result = false;
      }
      if (result === false) {
        alert("날짜를 확인해주세요.");
        this.search_param.from = dayjs().format("YYYY-MM-DD");
      }
    },
    endDateCheck: function () {
      let result = true;
      try {
        let date = this.search_param.to.split("-");
        let y = parseInt(date[0], 10),
          m = parseInt(date[1], 10),
          d = parseInt(date[2], 10);

        let dateRegex =
          /^(?=\d)(?:(?:31(?!.(?:0?[2469]|11))|(?:30|29)(?!.0?2)|29(?=.0?2.(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(?:\x20|$))|(?:2[0-8]|1\d|0?[1-9]))([-.\/])(?:1[012]|0?[1-9])\1(?:1[6-9]|[2-9]\d)?\d\d(?:(?=\x20\d)\x20|$))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\x20[AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;
        result = dateRegex.test(d + "-" + m + "-" + y);
      } catch (err) {
        result = false;
      }
      if (result === false) {
        alert("날짜를 확인해주세요.");
        this.search_param.to = dayjs().format("YYYY-MM-DD");
      }
    },
    texareaInput: function (e) {
      let val = e.target.value;

      let byte_length = this.getBytes(val);
      if (byte_length > 500) {
        val = val.substring(0, val.length - 1);
        e.target.value = val;
      }
      let event = new Event("input", {
        bubbles: true,
        cancelable: true,
      });
      e.target.dispatchEvent(event);
    },
    getBytes: function (msg) {
      let nbytes = 0;
      for (let i = 0; i < msg.length; i++) {
        let ch = msg.charAt(i);
        if (escape(ch).length > 4) {
          nbytes += 2;
        } else if (ch == "\n") {
          if (msg.charAt(i - 1) != "\r") {
            nbytes += 1;
          }
        } else if (ch == "<" || ch == ">") {
          nbytes += 4;
        } else {
          nbytes += 1;
        }
      }
      return nbytes;
    },
    onlyNumber: function (e) {
      let val = e.target.value;
      let replaceVal = val.replace(/[^0-9]/g, "");

      replaceVal = Number(replaceVal);
      if (replaceVal < 0) {
        e.target.value = "";
      }

      e.target.value = replaceVal.toLocaleString();

      let event = new Event("input", {
        bubbles: true,
        cancelable: true,
      });
      e.target.dispatchEvent(event);
    },
    selectClick: function () {
      if (this.$route.query?.supplier_code) {
        return;
      }
      this.option_list_show = !this.option_list_show;
      if (this.option_list_show) {
        this.border_bottom_radius_style =
          "border-bottom-left-radius: 0px; border-bottom-right-radius: 0px";
      } else {
        this.border_bottom_radius_style = "";
      }
    },
    searchSupplierList: function (e) {
      let val = e.target.value;
      if (!val) {
        return;
      }
      let supply_supplier_list = JSON.parse(localStorage.getItem("supply_supplier_list"));
      let search_list = [];
      for (const supply_supplier of supply_supplier_list) {
        if (supply_supplier.supplier_name.indexOf(val) > -1) {
          search_list.push(supply_supplier);
        }
      }
      this.supply_supplier_list = [];
      for (const search of search_list) {
        this.supply_supplier_list.push(search);
      }
    },
    supplierClick: async function (supplier) {
      this.select_supplier = supplier;
      this.selectClick();
      this.setSupplierSetting();
      await this.getSettlement(supplier);
    },
    setSupplierSetting: function () {
      let is_relation_order_item_code = false;
      if (this.relation_order_item_code) {
        is_relation_order_item_code = true;
      }

      const find = this.supply_supplier_setting.list.find(
        (e) => e.supplier_code == this.select_supplier.supplier_code
      );
      if (find) {
        // 개별 등록 (정산 상태 X)
        if (is_relation_order_item_code == false) {
          // 정산예정일 (일일정산)
          if (find.payment_period == "C") {
            this.settlement_schedule_at = dayjs().add(1, "day").format("YYYY-MM-DD");
          }

          // 정산예정일 (주간정산)
          if (find.payment_period == "B") {
            // 시작일
            let payment_start_day = dayjs().day(find.payment_start_day).format("YYYY-MM-DD");

            // 종료일
            let payment_end_day = dayjs(payment_start_day).add(1, "week").format("YYYY-MM-DD");

            // 시작일이 오늘 이후면 지난주의 시작 요일을 선택한다
            let diff1 = dayjs(payment_start_day).diff(dayjs(), "d", true);
            if (diff1 > 0) {
              payment_start_day = dayjs(payment_start_day).subtract(1, "week").format("YYYY-MM-DD");
              payment_end_day = dayjs(payment_end_day).subtract(1, "week").format("YYYY-MM-DD");
            }
            this.settlement_schedule_at = dayjs(payment_end_day).format("YYYY-MM-DD");
          }

          // 정산예정일 (월간정산)
          if (find.payment_period == "A") {
            // 이번달 마지막일을 구한다
            const last_day = dayjs().daysInMonth();
            // 설정일이 이번달 마지막 날짜보다 크면 이번달 말일 까지로 입력한다
            let end_day = find.payment_start_date;
            if (Number(find.payment_start_date) > Number(last_day)) {
              end_day = last_day;
            }
            if (Number(end_day) < 10) {
              end_day = `0${end_day}`;
            }
            let end_date = dayjs().format("YYYY-MM") + "-" + end_day;
            let diff1 = dayjs().diff(dayjs(end_date), "d", true);
            if (diff1 > 0) {
              end_date = dayjs(end_date).add(1, "month").format("YYYY-MM-DD");
            }

            
            this.settlement_schedule_at = dayjs(end_date).add(1, "day").format("YYYY-MM-DD");
          }
        } else {
          if (this.settlement_completion_at) {
            // 다음정산 예정일

            // 정산예정일 (일일정산)
            if (find.payment_period == "C") {
              this.settlement_schedule_at = dayjs().add(1, "day").format("YYYY-MM-DD");
            }

            // 정산예정일 (주간정산)
            if (find.payment_period == "B") {
              // 시작일
              let payment_start_day = dayjs().day(find.payment_start_day).format("YYYY-MM-DD");

              // 종료일
              let payment_end_day = dayjs(payment_start_day).add(1, "week").format("YYYY-MM-DD");

              // 시작일이 오늘 이후면 지난주의 시작 요일을 선택한다
              let diff1 = dayjs(payment_start_day).diff(dayjs(), "d", true);
              if (diff1 > 0) {
                payment_start_day = dayjs(payment_start_day).subtract(1, "week").format("YYYY-MM-DD");
                payment_end_day = dayjs(payment_end_day).subtract(1, "week").format("YYYY-MM-DD");
              }
              this.settlement_schedule_at = dayjs(payment_end_day).add(1, "day").format("YYYY-MM-DD");
            }

            // 정산예정일 (월간정산)
            if (find.payment_period == "A") {
              // 이번달 마지막일을 구한다
              const last_day = dayjs().daysInMonth();
              // 설정일이 이번달 마지막 날짜보다 크면 이번달 말일 까지로 입력한다
              let end_day = find.payment_start_date;
              if (Number(find.payment_start_date) > Number(last_day)) {
                end_day = last_day;
              }
              if (Number(end_day) < 10) {
                end_day = `0${end_day}`;
              }
              let end_date = dayjs().format("YYYY-MM") + "-" + end_day;
              this.settlement_schedule_at = dayjs(end_date).add(1, "day").format("YYYY-MM-DD");
            }
          }
        }
      }
    },
    save: async function () {
      if (!this.select_supplier.supplier_code) {
        alert("필수값을 확인해주세요.");
        return;
      }
      if (this.amount == 0 || !this.amount) {
        alert("필수값을 확인해주세요.");
        return;
      }
      let relation_order_item_code = [];
      if (this.relation_order_item_code.length > 0) {
        relation_order_item_code = this.relation_order_item_code.split(",");
      }

      let amount = this.amount.replace(/[^0-9]/g, "");
      amount = Number(amount);

      const params = {
        supplier_code: this.select_supplier.supplier_code,
        settlement_schedule_at: this.settlement_schedule_at,
        settlement_category: this.settlement_category,
        amount,
        reason: this.reason,
        relation_order_item_code,
      };
      await this.$store.dispatch("supply/setSettlementManual", params);
      alert("저장되었습니다");
      this.$router.push({
        name: "detail",
        query: {
          from: dayjs().format("YYYY-MM-DD"),
          to: dayjs().add(2, "month").format("YYYY-MM-DD"),
          date_search_type: "schedule",
        },
      });
    },
    // 정산 예정 내역 조회
    getSettlement: async function (params) {
      let set_param = {
        type: "schedule",
        supplier_code: params.supplier_code,
        offset: 0,
        limit: 20,
      };
      this.settlement_schedule_at_option_list = [];
      const settlement_result = await this.$store.dispatch("supply/getSettlement", set_param);
      for (const settlement of settlement_result.list) {
        this.settlement_schedule_at_option_list.push(this.dateFormat(settlement.settlement_schedule_at));
      }

      let count = Math.ceil(Number(settlement_result.total_count) / set_param.limit);
      for (let i=1; i < count; i++) {
        set_param.offset = (i) * set_param.limit;
        const add_settlement_result = await this.$store.dispatch("supply/getSettlement", set_param);
        for (const settlement of add_settlement_result.list) {
          this.settlement_schedule_at_option_list.push(this.dateFormat(settlement.settlement_schedule_at));
        }
      }

      const find = this.settlement_schedule_at_option_list.find((e) => e == this.dateFormat(this.settlement_schedule_at));
      if (!find) {
        this.settlement_schedule_at_option_list.unshift(this.dateFormat(this.settlement_schedule_at));
      }

    },
    sleep: function (ms = 500) {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    },
    dateFormat: function (date) {
      if (!date) {
        return date;
      }
      return dayjs(date).format("YYYY-MM-DD");
    },
  },

  components: {
    DatePicker,
  },
  filters: Filters,
};
